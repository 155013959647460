import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal, Row } from 'react-bootstrap';
import PreSelect from './components/pre-select';
import PostSelect from './components/post-select';
import { FileData, SelectFileProps } from './types';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import Icon from '../../../../../../../../shared/design-system/components/atoms/icon';
import { constants } from '../../../../../../../../shared/enums/constants';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';
import { isWhiteLabel } from '../../../../../../../../shared/utils/user-details';

const SelectFile = ({
  onFinish,
  onClose,
  handleCSVImport,
}: SelectFileProps) => {
  const [fileData, setFileData] = useState<FileData>(null);
  const [csvError, setCsvError] = useState<{
    error: boolean;
    message_one: string;
    message_two: string;
  }>({
    error: false,
    message_one: '',
    message_two: '',
  });

  const onRemoveHandler = () => {
    setFileData(null);

    if (csvError) {
      setCsvError({
        error: false,
        message_one: '',
        message_two: '',
      });
    }
  };

  const hasMoreThanTwoEmptyStrings = (arr: string[]) => {
    const emptyStringsCount = arr.filter((item) => item === '').length;
    return emptyStringsCount >= 2;
  };

  const hasMoreThanTwoIdenticalNonEmptyStrings = (arr: string[]) => {
    const nonEmptyArray = arr.filter((item) => item !== '');

    return nonEmptyArray.some(
      (item, index) => nonEmptyArray.indexOf(item) !== index,
    );
  };

  const onSelectSuccess = useCallback(
    (file, headers, firstRow, count) => {
      const payload = {
        file,
        headers,
        firstRow,
        importedContactsCount: count - 1,
      };

      handleCSVImport(payload);
      setFileData(payload);

      const hasEmptyValues = hasMoreThanTwoEmptyStrings(headers);
      const hasDuplicateValues = hasMoreThanTwoIdenticalNonEmptyStrings(
        headers,
      );

      if (hasEmptyValues && hasDuplicateValues) {
        setCsvError({
          error: true,
          message_one:
            'The uploaded file has duplicate column headers and blank columns between data columns.',
          message_two: 'Please fix this and re-upload the file.',
        });
      } else if (hasEmptyValues) {
        setCsvError({
          error: true,
          message_one:
            'The uploaded file has multiple blank columns between data columns.',
          message_two: 'Please remove them and re-upload the file.',
        });
      } else if (hasDuplicateValues) {
        setCsvError({
          error: true,
          message_one: 'The uploaded file has duplicate column headers.',
          message_two:
            'Please ensure the column header is unique and re-upload the file.',
        });
      }

      if (hasEmptyValues || hasDuplicateValues) {
        toaster.error('Import prospect CSV failed', { theme: Theme.New });
      } else {
        toaster.success(`${file.name} file selected successfully`, {
          theme: Theme.New,
        });
      }
    },
    [setFileData],
  );

  const onContinue = () => onFinish(fileData);
  const { t } = useTranslation();

  const clsNames = classNames([
    'select-file import-contact-stage',
    {
      'pre-select-stage': !fileData,
      'post-select-stage': fileData,
      'csv-error': csvError.error,
    },
  ]);

  return (
    <>
      <div className={clsNames}>
        {!fileData ? (
          <div className="select-file-content import-contact-stage-content">
            <PreSelect onSelectSuccess={onSelectSuccess} />
          </div>
        ) : (
          <div className="select-file-content import-contact-stage-content">
            <PostSelect
              fileName={fileData.file.name}
              onRemove={onRemoveHandler}
              csvError={csvError}
            />
          </div>
        )}
        <div className="import-contacts-footer">
          <Modal.Footer>
            <Row className="download-sample-file">
              <a
                id={ProductTour.DownloadSampleCSV}
                className="regular-2 blue-txt-6 ml-1"
                href={
                  isWhiteLabel()
                    ? constants.DOWNLOAD_SAMPLE_WHITELABEL_FILE_URL
                    : constants.DOWNLOAD_SAMPLE_FILE_URL
                }
                download
              >
                <Icon
                  identifier="software-download"
                  className="regular-4 blue-txt-6 mr-1"
                />
                {t(`Try sample file`)}
              </a>
            </Row>
            <Button
              variant={Button.Variant.Default}
              disabled={!fileData}
              onClick={onClose}
            >
              {t(`Cancel`)}
            </Button>
            <Button
              id={ProductTour.UploadFileNextStep}
              variant={Button.Variant.Primary}
              disabled={!fileData || csvError.error}
              onClick={onContinue}
              className="header-btn"
            >
              {t(`Next`)}
            </Button>
          </Modal.Footer>
        </div>
      </div>
    </>
  );
};

export default SelectFile;
