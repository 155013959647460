import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import EmailAccountsActions from './email-accounts-actions';
import { EmailAccountsFilters } from '../../types';
import {
  bulkEmailUpdateRequest,
  emailAccountTagsAssignRequest,
  getEmailAccountTagsRequest,
} from '../../extra-actions';
import { getAgencyClientsDropdown } from '../../../../../agency-client-management/agency-client-slice';
import { GetAgencyClientDropdownRequestPayload } from '../../../../../agency-client-management/types/request-payload';
import { EmailAccountTagsAssignPayload } from '../../../../types/request-payload';

const mapStateToProps = (state: RootState) => ({
  planCode: state.home.subscription?.planCode,
  selectedEmailAccounts: state.emailAccount.selectedEmailAccounts,
  bulkSelectedEmailAccounts: state.emailAccount.bulkSelectedEmailAccounts,

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,

  bulkEmailUpdateRequestStatus:
    state.emailAccount.bulkEmailUpdateRequest.status,
  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  emailAccountsFilters: state.emailAccount.emailAccountsFilters,

  emailAccountTagsList: state.emailAccount.emailAccountTagsList,
});

const mapDispatchToProps = {
  sendBulkEmailAccountUpdateRequest: (payload) =>
    bulkEmailUpdateRequest(payload),
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetEmailAccountsTagsRequest: () => getEmailAccountTagsRequest(),
  sendEmailAccountTagsAssignRequest: (payload: EmailAccountTagsAssignPayload) =>
    emailAccountTagsAssignRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onAddEmailAccount: VoidFunction;
  openBulkEmailUpdateModal?: () => void;
};

export default connector(EmailAccountsActions);
