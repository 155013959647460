import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../../app-constants';
import Icon from '../../atoms/icon';
import EmailVerificationError from '../../atoms/email-verification-error/email-verification-error';

type IProps = {
  availableCredits: number;
  requiredCredits: number;
};

const ImportEmailVerification: React.FC<IProps> = ({
  availableCredits,
  requiredCredits,
}) => {
  const { t } = useTranslation();
  const [
    isRenderEvPurchaseActionContent,
    setIsRenderEvPurchaseActionContent,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsRenderEvPurchaseActionContent(requiredCredits > availableCredits);
  }, [availableCredits, requiredCredits]);

  const renderEmailVerificationInfoContent = (
    <div className="p-2">
      <div className="valid-contact d-flex flex-row align-items-center mt-1 mb-2">
        <span className="icon">
          <Icon identifier="user" /> <Icon identifier="check" />
        </span>
        <div className="valid-status-name status-name text">
          {t('messages.valid_prospect')}
        </div>
        <div className="status-content text">
          {t('messages.high_delivery_chances')}
        </div>
      </div>
      <div className="risky-contact d-flex flex-row align-items-center mt-1 mb-2">
        <span className="icon">
          <Icon identifier="user" /> <Icon identifier="info" />
        </span>
        <div className="risky-status-name status-name text">
          {t('messages.risky_prospect')}
        </div>
        <div className="status-content">
          {t('messages.low_delivery_chances')}
        </div>
      </div>
    </div>
  );

  return (
    <div className="review-section-container mt-2">
      <div className="review-section-title-container">
        <img src={Images.Shield} />
        <span className="review-section-title">{'Email Verification'}</span>
      </div>
      <div className="email-verification-desc-container text">
        <span>
          {!isRenderEvPurchaseActionContent
            ? t('messages.email_verification_title')
            : t('messages.email_verification_action_required_message')}
        </span>
        {!isRenderEvPurchaseActionContent ? (
          renderEmailVerificationInfoContent
        ) : (
          <EmailVerificationError
            availableCredits={availableCredits}
            requiredCredits={requiredCredits}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ImportEmailVerification);
