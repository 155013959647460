import React, { useEffect } from 'react';
import SearchInput from '../../../../../../../../shared/design-system/ui/search-input';
import { constants } from '../../../../../../../../shared/enums/constants';
import {
  ProspectCountParams,
  ProspectPagination,
  ProspectParams,
} from '../../../../../../types';

type IProps = {
  sendGetProspectRequest: (params: ProspectParams) => void;
  sendGetProspectCountRequest: (params: ProspectCountParams) => void;
  saveSearch: (query: string) => void;
  changePagination: (params: ProspectPagination) => void;
  searchValue: string;
};

const ProspectListSearchBar: React.FC<IProps> = ({
  sendGetProspectRequest,
  sendGetProspectCountRequest,
  saveSearch,
  changePagination,
  searchValue,
}) => {
  useEffect(() => {
    if (searchValue) {
      saveSearch(searchValue);
    }
  }, [searchValue, saveSearch]);

  const onSearchChangeHandler = (value: string) => {
    saveSearch(value);
  };

  const handleSubmit = () => {
    changePagination({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
    });
    sendGetProspectRequest({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
      search: searchValue,
    });
    sendGetProspectCountRequest({ search: searchValue });
  };

  const handleClearSearch = () => {
    changePagination({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
    });
    sendGetProspectRequest({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
      search: '',
    });
    sendGetProspectCountRequest({ search: '' });
  };

  return (
    <div className="search-bar">
      <SearchInput
        onSearch={handleSubmit}
        search={searchValue}
        setSearch={onSearchChangeHandler}
        onClearSearch={handleClearSearch}
      />
    </div>
  );
};

export default ProspectListSearchBar;
