/* eslint-disable @typescript-eslint/no-shadow */
import React, { forwardRef, useState, useImperativeHandle } from 'react';
import SelectFile from '../select-file';
import MapFields from '../map-fields';
import { FileData } from '../select-file/types';
import { ImportConfigData, ImportFlowProps } from './types';
import {
  AddTagsToImportedContactsPayload,
  MapConfig,
} from '../../../../../../types';
import { ConflictAction } from '../../../../../../enums';
import { Stage, stageOptions } from './stage';
import EmailVerification from '../email-verification';
import { RowDataWithTypeVerification } from '../map-fields/types';
import RadioButtonGroup from '../../../../../../../../shared/design-system/components/atoms/radio-button/radio-button';
import { Field } from '../../../../../../../settings/types/field';

const ImportFlow = forwardRef(
  (
    {
      isRequestPending,
      onFinish,
      fields,
      steps,
      isEmailVerification,
      onClose,
      fromModule,
      warning,
      showPurchaseEvCreditsModal,
      isPurchaseEmailVerificationCreditsLoading,
      handleCSVImport,
    }: ImportFlowProps,
    ref,
  ) => {
    const [stage, setStage] = useState<Stage>(Stage.SelectFile);

    const onStageChange = (stage: Stage) => {
      setStage(stage);
    };

    const [fileData, setFileData] = useState<FileData>(null);
    const [
      importConfigParam,
      setImportConfigParam,
    ] = useState<ImportConfigData>(null);

    const selectFileFinishHandler = (fileData: FileData) => {
      setFileData(fileData);
      setStage(Stage.MapFields);
    };

    const mapFieldsFinishHandler = (
      mapConfig: MapConfig,
      conflictAction: ConflictAction,
      stepId: number,
    ) => {
      setStage(Stage.EmailVerification);
      setImportConfigParam({
        file: fileData.file,
        mapConfig,
        conflictAction,
        stepId,
      });
    };

    const handleOnFinishWithoutVerification = (
      tags?: AddTagsToImportedContactsPayload,
    ) => {
      onFinish({
        ...importConfigParam,
        tags,
      });
    };

    const verificationResponse = (
      includeRiskyContact: boolean,
      includeVerification: boolean,
      tags?: AddTagsToImportedContactsPayload,
    ) => {
      if (includeVerification) {
        onFinish({
          ...importConfigParam,
          includeRiskyContact,
          includeVerification,
          tags,
        });
      } else {
        onFinish({
          ...importConfigParam,
          includeVerification,
          tags,
        });
      }
    };

    const stageOptionsWithDisabled = stageOptions.map((option) => ({
      ...option,
      // Disabled all stages except the current stage.
      ...(option.value !== stage && { disabled: true }),
    }));

    const filteredFields = fields.reduce((result, field: Field) => {
      const name = field.label.replace(' ', '').toLowerCase();
      if (name !== 'companydomain') {
        result.push(field);
      }
      return result;
    }, []);

    const handleCSVImportCount = (fileData: FileData) => {
      setFileData(fileData);
      handleCSVImport(fileData?.importedContactsCount);
    };

    useImperativeHandle(ref, () => ({
      importAnyWay() {
        selectFileFinishHandler(fileData);
      },
    }));

    return (
      <div className="import-flow overflow-hidden">
        <div className="radio-tabs-wrapper">
          <RadioButtonGroup
            name="import-flow-stages"
            value={stage}
            options={stageOptionsWithDisabled}
            onChange={onStageChange}
          />
        </div>
        <div className="import-flow-stage-wrapper overflow-hidden">
          {stage === Stage.SelectFile && (
            <SelectFile
              onFinish={selectFileFinishHandler}
              onClose={onClose}
              handleCSVImport={handleCSVImportCount}
            />
          )}
          {stage === Stage.MapFields && (
            <MapFields
              headers={fileData.headers}
              firstRow={fileData.firstRow.map(
                (el): RowDataWithTypeVerification => ({
                  value: el,
                  isTypeValid: null,
                }),
              )}
              fields={filteredFields}
              onFinish={mapFieldsFinishHandler}
              isRequestPending={isRequestPending}
              steps={steps}
              onClose={onClose}
              fromModule={fromModule}
            />
          )}
          {stage === Stage.EmailVerification && (
            <EmailVerification
              warning={warning}
              onClick={verificationResponse}
              isRequestPending={isRequestPending}
              fileData={fileData}
              onBuyNow={(remainingCredit) =>
                showPurchaseEvCreditsModal(remainingCredit)
              }
              isPurchaseEmailVerificationCreditsLoading={
                isPurchaseEmailVerificationCreditsLoading
              }
              isEmailVerification={isEmailVerification}
              handleOnFinishWithoutVerification={
                handleOnFinishWithoutVerification
              }
            />
          )}
        </div>
      </div>
    );
  },
);

export default ImportFlow;
