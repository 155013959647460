export const getMergedProspects = (arr1 = [], arr2 = []) => {
  // Create a map to store merged objects
  const mergedMap = new Map();

  // Process the first array
  arr1.forEach((item) => {
    mergedMap.set(item.id, { ...item });
  });

  // Process the second array
  arr2.forEach((item) => {
    if (mergedMap.has(item.id)) {
      const existingItem = mergedMap.get(item.id);

      const existingAttributes = Array.isArray(existingItem.attributes)
        ? existingItem.attributes
        : [];
      const newAttributes = Array.isArray(item.attributes)
        ? item.attributes
        : [];

      // Merge the properties
      Object.assign(existingItem, item);
      // Combine attr arrays
      existingItem.attributes = [...existingAttributes, ...newAttributes];
    } else {
      // If not in mergedMap, add it
      mergedMap.set(item.id, { ...item });
    }
  });

  // Convert the map back to an array
  return Array.from(mergedMap.values());
};
