import { Field } from '../../../../settings/types/field';
import { ProspectSortBy } from '../../../enums/prospect';
import { ProspectAttribute } from '../types';

const prospectSystemAttributes: ProspectAttribute[] = [
  {
    id: 'name',
    label: 'Name',
    isDefault: true,
    isNonRemovable: true,
    sortBy: ProspectSortBy.FirstName,
  },
  {
    id: 'email',
    label: 'Email',
    isDefault: true,
    isNonRemovable: true,
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    isDefault: true,
  },
  {
    id: 'prospectStatus',
    label: 'Prospect Status',
    isDefault: true,
  },
  {
    id: 'prospectOutcomes',
    label: 'Prospect Outcomes',
    isDefault: true,
  },
  {
    id: 'prospectTags',
    label: 'Prospect Tags',
    isDefault: true,
  },
  {
    id: 'lastContacted',
    label: 'Last Contacted',
    isDefault: false,
  },
  {
    id: 'department',
    label: 'Department',
    isDefault: true,
  },
  {
    id: 'company',
    label: 'Company',
    isDefault: true,
  },
  {
    id: 'companyIndustry',
    label: 'Company Industry',
    isDefault: true,
  },
  {
    id: 'companyWebsite',
    label: 'Company Website',
    isDefault: true,
  },
  {
    id: 'prospectOwner',
    label: 'Prospect Owner',
    isDefault: true,
  },
  {
    id: 'creationDate',
    label: 'Creation Date',
  },
  {
    id: 'lastUpdated',
    label: 'Last Updated',
  },
  {
    id: 'sequenceAssociated',
    label: 'Sequence Associated',
  },
  {
    id: 'verificationStatus',
    label: 'Verification Status',
  },
  {
    id: 'creationSource',
    label: 'Creation Source',
  },
  {
    id: 'numberOfEmailsSent',
    label: 'Number of Emails sent',
  },
  {
    id: 'clientAssociated',
    label: 'Client Associated',
  },
  {
    id: 'linkedIn',
    label: 'LinkedIn',
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    id: 'experience',
    label: 'Experience',
  },
  {
    id: 'twitter',
    label: 'Twitter',
  },
  {
    id: 'facebook',
    label: 'Facebook',
  },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'country',
    label: 'Country',
  },
  {
    id: 'companyLinkedIn',
    label: 'Company LinkedIn',
  },
  {
    id: 'companyPhone',
    label: 'Company Phone',
  },
  {
    id: 'companyDomain',
    label: 'Company Domain',
  },
  {
    id: 'companySize',
    label: 'Company Size',
  },
  {
    id: 'companyRevenue',
    label: 'Company Revenue',
  },
  {
    id: 'companyFoundedYear',
    label: 'Company Founded Year',
  },
  {
    id: 'numberOfOpens',
    label: 'Number of Opens',
    isDefault: true,
  },
  {
    id: 'numberOfClicks',
    label: 'Number of Clicks',
    isDefault: true,
  },
  // {
  //   id: 'numberOfReplies',
  //   label: 'Number of Replies',
  //   isDefault: true,
  // },
  {
    id: 'recentlyOpenDate',
    label: 'Recently open date',
  },
  {
    id: 'recentlyClickDate',
    label: 'Recently click date',
  },
  {
    id: 'recentlyReplyDate',
    label: 'Recently reply date',
  },
];

const skipSystemAttributes = ['First Name', 'Last Name', 'Email', 'Website'];

export const getProspectSystemAttributes = (fields: Field[]) =>
  prospectSystemAttributes.map((attribute) => {
    // Check if the field label exists in skipSystemAttributes array
    if (skipSystemAttributes.includes(attribute.label)) {
      return attribute;
    }

    // Check if the field is an API field and update the id based on fields
    const fieldFromApi = fields.find(
      (field) => field.label === attribute.label,
    );

    if (fieldFromApi) {
      return { ...attribute, id: fieldFromApi.id.toString() };
    }

    return attribute;
  });
