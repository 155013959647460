/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import EmailVerificationContent from '../../../../../../../../shared/design-system/components/molecules/email-verification-content';
import TagProspectsContent from '../../../../../../../../shared/design-system/components/molecules/tag-prospects-content/tag-prospects-content';
import { parseTagPayload } from '../../../../../../../../shared/utils/tags-payload-parser';
import { EmailVerificationProcess } from '../../../../../../../../shared/design-system/components/molecules/email-verification-content/enum';
import type { IProps } from './email-verification-container';
import ImageIcon from '../../../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../../../shared/app-constants';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';

enum ButtonIdentifier {
  SKIP_VERIFICATION = 'skip',
  CONTINUE_VERIFICATION = 'continue',
}

const EmailVerification: React.FC<IProps> = ({
  onClick,
  tags,
  fileData,
  onBuyNow,
  isPurchaseEmailVerificationCreditsLoading,
  sendGetEmailVerificationCreditsRequestStatus,
  getEmailVerificationCreditsResponse,
  warning,
  sendGetTagsRequest,
  isEmailVerification,
  handleOnFinishWithoutVerification,
  isRequestPending,
}) => {
  const { t } = useTranslation();
  const [includeRiskyContact] = useState<boolean>(false);

  const [buttonIdentifier, setButtonIdentifier] = useState<string>(null);

  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [
    isEvPurchaseActionRequired,
    setIsEvPurchaseActionRequired,
  ] = useState<boolean>(false);
  const [remainingCredit, setRemainingCredit] = useState<number>(0);
  const [isRenderContentLoading, setIsRenderContentLoading] = useState<boolean>(
    true,
  );

  const onButtonClickHandler = (
    includeRiskyContact: boolean,
    addVerification: boolean,
    buttonIdentifier: ButtonIdentifier,
  ) => {
    setButtonIdentifier(buttonIdentifier);
    onClick(includeRiskyContact, addVerification, parseTagPayload(tagsToAdd));
  };

  const buyCreditsHandler = () => {
    setButtonIdentifier(null);
    onBuyNow(remainingCredit);
  };

  useEffect(() => {
    if (isEmailVerification) {
      const { importedContactsCount } = fileData;
      const { creditsAvailable } = getEmailVerificationCreditsResponse || {
        creditsAvailable: 0,
      };
      const isActionRequired = importedContactsCount > creditsAvailable;

      setIsEvPurchaseActionRequired(isActionRequired);

      if (isActionRequired) {
        setRemainingCredit(importedContactsCount - creditsAvailable);
      }
    }
  }, [isEmailVerification, getEmailVerificationCreditsResponse, fileData]);

  useEffect(() => {
    sendGetTagsRequest();
  }, []);

  useEffect(() => {
    const isLoading =
      isEmailVerification &&
      sendGetEmailVerificationCreditsRequestStatus === RequestStatus.Pending &&
      getEmailVerificationCreditsResponse === null;

    setIsRenderContentLoading(isLoading);
  }, [
    isEmailVerification,
    sendGetEmailVerificationCreditsRequestStatus,
    getEmailVerificationCreditsResponse,
  ]);

  return (
    <>
      <div className="email-verification overflow-auto">
        <TagProspectsContent
          tags={tags}
          tagsToAdd={tagsToAdd}
          setTagsToAdd={setTagsToAdd}
        />

        {isEmailVerification && (
          <>
            {!isRenderContentLoading ? (
              <>
                <EmailVerificationContent
                  verificationProcess={EmailVerificationProcess.ImportFlow}
                  availableCredits={
                    getEmailVerificationCreditsResponse?.creditsAvailable
                  }
                  requiredCredits={fileData.importedContactsCount}
                />
                {!isEvPurchaseActionRequired && (
                  <div className="email-verification-content-footer">
                    <div className="risky-prospect-import-container font-14 d-flex align-items-center">
                      {warning}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="content-loader">
                <ImageIcon src={Images.Loader} />
              </div>
            )}
          </>
        )}
      </div>

      {!isRenderContentLoading && (
        <div className="import-contacts-footer">
          <Modal.Footer>
            {isEmailVerification && (
              <Button
                variant={Button.Variant.Outlined}
                theme={Button.Theme.Ghost}
                onClick={() =>
                  onButtonClickHandler(
                    includeRiskyContact,
                    false,
                    ButtonIdentifier.SKIP_VERIFICATION,
                  )
                }
                isLoading={
                  buttonIdentifier === ButtonIdentifier.SKIP_VERIFICATION
                }
                disabled={
                  isPurchaseEmailVerificationCreditsLoading ||
                  buttonIdentifier === ButtonIdentifier.SKIP_VERIFICATION ||
                  buttonIdentifier === ButtonIdentifier.CONTINUE_VERIFICATION
                }
              >
                {t(`Skip verification`)}
              </Button>
            )}

            {isEmailVerification && (
              <>
                {isEvPurchaseActionRequired ? (
                  <Button
                    variant={Button.Variant.Primary}
                    onClick={() => buyCreditsHandler()}
                    isLoading={isPurchaseEmailVerificationCreditsLoading}
                    disabled={isPurchaseEmailVerificationCreditsLoading}
                  >
                    {t('labels.buy_credits')}
                  </Button>
                ) : (
                  <Button
                    id={ProductTour.ReviewAndImport}
                    variant={Button.Variant.Primary}
                    onClick={() =>
                      onButtonClickHandler(
                        includeRiskyContact,
                        true,
                        ButtonIdentifier.CONTINUE_VERIFICATION,
                      )
                    }
                    isLoading={
                      buttonIdentifier ===
                      ButtonIdentifier.CONTINUE_VERIFICATION
                    }
                    disabled={
                      buttonIdentifier ===
                        ButtonIdentifier.CONTINUE_VERIFICATION ||
                      buttonIdentifier === ButtonIdentifier.SKIP_VERIFICATION
                    }
                  >
                    Continue
                  </Button>
                )}
              </>
            )}
            {!isEmailVerification && (
              <Button
                id={ProductTour.ReviewAndImport}
                variant={Button.Variant.Primary}
                onClick={() =>
                  handleOnFinishWithoutVerification(parseTagPayload(tagsToAdd))
                }
                isLoading={isRequestPending}
                disabled={isRequestPending}
              >
                Continue
              </Button>
            )}
          </Modal.Footer>
        </div>
      )}
    </>
  );
};

export default EmailVerification;
