import React from 'react';

// Types and Enums
import { AlertTriangle } from '@saleshandy/icons';
import type { EmailAccountsFilters, IProps, IState } from './types';
import { Routes } from '../../../../shared/routes';
import { ProfileProgressSteps } from '../../../../shared/types/user-setting';
import { EmailAccount, EmailAccountStatus } from '../../types/email-account';
import { SMTPIMAP } from '../../enums/smtp-or-imap';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { UpdateEmailTabKeys } from './components/update-email-account/types';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import SubscriptionStatus from '../billing-subscription/enums/subscription-status';
import { EmailAccountMethod } from '../../enums/email-account';

// Shared Components
import toaster, { Theme } from '../../../../shared/toaster';
import Alert from '../../../../shared/design-system/ui/alert';

// Modals
import ErrorLogModal from './components/error-log-modal';
import AddEmailAccountModal from './components/add-email-account-modal';
import AddBulkImportModal from './components/add-email-bulk-import-modal';
import DeleteEmailAccountModal from './components/delete-email-account-modal';
import DisconnectEmailAccountModal from './components/disconnect-email-account-modal';
import ConnectSMTPIMAPAccountModal from './components/connect-smtp-imap-account-modal';
import RestrictionErrorModal from '../../../../shared/components/restriction-error-modal';
import NoSeatAvailableForEmailModal from './components/no-seat-available-for-email-modal';
import ConfirmationModal from '../../../../shared/design-system/components/atoms/confirmation-modal';
import ReactivateSubscriptionModal from '../billing-subscription/components/reactivate-subscription-modal';

// Components
import HeaderBanner from '../header-banner';
import EmailAccountsHeader from './components/email-accounts-header';
import EmailAccountsActions from './components/email-accounts-actions';
import EmailAccountsEmptyUI from './components/email-accounts-empty-ui';
import EmailAccountsNoSearchUI from './components/email-accounts-no-search-ui';
import EmailAccountsTable from './components/email-accounts-table';

// Shared Utility Functions
import { planError } from '../../../../shared/utils/errors/plan-permission-error/plan-error';
import { isValueBasedPlan } from '../billing-subscription/components/outreach-plans/utils/helper';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import {
  contactAdmin,
  redirectWithoutRefresh,
  redirectWithRefresh,
  executeOnRequestStatusWithPrevStatusCheck,
  SubscriptionPlans,
  SubscriptionPlanTitle,
  getAccountEmailSendingDetails,
  getIsRequestPending,
  executeOnErrorWithErrorCheck,
} from '../../../../shared/utils';
import {
  getEmailAccountType,
  shouldShowAddEmailAccountModal,
  showHideLoading,
  showToasterWithErrorCheck,
  isSavedSMTPAccount,
  setURLParamsForEmailAccountsFilters,
  getInitialFiltersForEmailAccounts,
  setEmailAccountsSearchQuery,
} from './utils/helpers';
import {
  getEmailAccountId,
  setShouldRefreshEmailSetupScoreValue,
} from './components/update-email-account/helpers';
import BulkEmailUpdateModal from './components/bulk-email-update-modal/bulk-email-update-container';
import { GlobalSettings } from '../../../../shared/components/global-settings-wrapper';
import { updateEmailAccountsFilters } from './email-account-slice';

class EmailAccounts extends React.Component<IProps, IState> {
  private emailAccountSettingPayload;

  constructor(props) {
    super(props);
    this.emailAccountSettingPayload = React.createRef();
    this.state = {
      showAddEmailAccountModal: false,
      showBulkModal: false,
      showBulkEmailUpdateModal: false,
      showDisconnectEmailAccountModal: false,
      showDeleteEmailAccountModal: false,
      showEmailAccountQuotaChangeConfirmationModal: false,
      actionEmailAccount: null,
      quotaChangeConfirmationMessage: '',
      showNoSeatAvailableForEmailModal: false,
      showConnectAndSaveSMTPIMAPModel: false,
      emailAccountId: null,
      showReactivateSubscriptionModal: false,
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
      isErrorLogModalVisible: false,
      errorLogAccountType: SMTPIMAP.SMTP,
      errorLog: '',

      resetSelected: false,
      showBulkEmailAccountUpdateConfirmationModal: false,
    };

    this.makeDefaultEmailAccount = this.makeDefaultEmailAccount.bind(this);

    this.showAddEmailAccountModal = this.showAddEmailAccountModal.bind(this);
    this.hideAddEmailAccountModal = this.hideAddEmailAccountModal.bind(this);
    this.showDisconnectEmailAccountModal = this.showDisconnectEmailAccountModal.bind(
      this,
    );
    this.hideDisconnectEmailAccountModal = this.hideDisconnectEmailAccountModal.bind(
      this,
    );

    this.showDeleteEmailAccountModal = this.showDeleteEmailAccountModal.bind(
      this,
    );
    this.hideDeleteEmailAccountModal = this.hideDeleteEmailAccountModal.bind(
      this,
    );
    this.addEmailAccountHandler = this.addEmailAccountHandler.bind(this);
    this.onDisconnectEmailAccountSubmit = this.onDisconnectEmailAccountSubmit.bind(
      this,
    );
    this.onDeleteEmailAccountModalSubmit = this.onDeleteEmailAccountModalSubmit.bind(
      this,
    );

    this.onHideEmailAccountQuotaChangeConfirmationModal = this.onHideEmailAccountQuotaChangeConfirmationModal.bind(
      this,
    );

    this.onSubmitEmailAccountQuotaChangeConfirmationModal = this.onSubmitEmailAccountQuotaChangeConfirmationModal.bind(
      this,
    );
    this.showAddEmailAccountModalHandler = this.showAddEmailAccountModalHandler.bind(
      this,
    );
    this.hideNoSeatAvailableForEmailModal = this.hideNoSeatAvailableForEmailModal.bind(
      this,
    );
    this.showConnectAndSaveSmtpImapAccountModel = this.showConnectAndSaveSmtpImapAccountModel.bind(
      this,
    );
    this.hideReactivateSubscriptionModal = this.hideReactivateSubscriptionModal.bind(
      this,
    );
    this.hideRestrictionErrorModal = this.hideRestrictionErrorModal.bind(this);
    this.upgradePlanWarning = this.upgradePlanWarning.bind(this);
    this.handleResetSelected = this.handleResetSelected.bind(this);
    this.onHideBulkEmailUpdateConfirmationModal = this.onHideBulkEmailUpdateConfirmationModal.bind(
      this,
    );
    this.onSubmitBulkEmailUpdateConfirmationModal = this.onSubmitBulkEmailUpdateConfirmationModal.bind(
      this,
    );
    this.onShowBulkEmailUpdateConfirmationModal = this.onShowBulkEmailUpdateConfirmationModal.bind(
      this,
    );
  }

  async componentDidMount() {
    const {
      sendGetEmailAccountsRequest,
      showAddEmailAccountModalProp,
      sendGetSmtpImapAccountDetailsRequest,
      resetSelectedEmailAccounts,
      history,
      emailAccountFilters,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const hashId = params.get('hashId');
    const emailAccountId = hashId && getEmailAccountId(hashId);
    const emailAccountType = params.get('emailAccountType');
    const showAddEmailAccountModal = params.get('showAddEmailAccountModal');
    const showAddSmtpImapAccountModal = params.get('connect-smtp');
    const message = params.get('message');
    const isConnected = params.get('isConnected');

    sendGetEmailAccountsRequest(emailAccountFilters);
    setURLParamsForEmailAccountsFilters(emailAccountFilters);
    resetSelectedEmailAccounts();

    if (showAddEmailAccountModalProp || showAddEmailAccountModal) {
      this.showAddEmailAccountModalHandler();
    }
    if (emailAccountType && emailAccountId) {
      sendGetSmtpImapAccountDetailsRequest(+emailAccountId);
    }
    if (showAddSmtpImapAccountModal) {
      const emailAccountIdParam = params.get('emailAccountId');
      if (emailAccountIdParam) {
        this.setState({ emailAccountId: Number(emailAccountIdParam) });
      }
      this.showConnectAndSaveSmtpImapAccountModel();
    }

    if (message) {
      toaster[isConnected === 'true' ? 'success' : 'error'](message, {
        theme: Theme.New,
      });
      history.replace({
        search: '',
      });
    }
  }

  async componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      connectEmailAccountRequestStatus,
      connectEmailAccountRequestError,
      disconnectEmailAccountRequestStatus,
      disconnectEmailAccountRequestMessage,
      disconnectEmailAccountRequestError,
      setDefaultEmailAccountRequestStatus,
      setDefaultEmailAccountRequestError,
      deleteEmailAccountRequestStatus,
      deleteEmailAccountRequestMessage,
      deleteEmailAccountRequestError,
      updateEmailAccountRequestStatus,
      updateEmailAccountRequestMessage,
      updateEmailAccountRequestError,
      authUrl,
      isLoading,
      sendGetEmailAccountsRequest,
      showAddEmailAccountModalProp,
      getConnectedUsersAndEmailAccountsRequestStatus,
      getConnectedUsersAndEmailAccountsRequestError,
      resetConnectedUsersAndEmailAccountsRequest,
      getConnectSmtpImapRequestStatus,
      getConnectSmtpImapRequestMessage,
      sendConnectSmtpImapResponse,
      sendGetSmtpImapAccountDetailsRequestStatus,
      sendUpdateSmtpImapAccountRequestStatus,
      sendUpdateEmailAccountRequestMessage,
      resetUpdateSmtpImapAccountDetails,
      sendGetReactivateSubscriptionRequestStatus,
      resetReactivateSubscriptionResponse,
      sendGetReactivateSubscriptionResponse,
      sendGetReactivateSubscriptionRequestError,
      sendGetUserSettingsRequest,
      sendUpdateSmtpImapAccountDetailsResponse,
      updateProfileProgressRequestStatus,
      importEmailAccountCsvRequestStatus,
      importEmailAccountCsvRequestMessage,
      clearEmailAccountsSelection,
      t,
      bulkEmailUpdateRequestMessage,
      bulkEmailUpdateRequestStatus,
      bulkEmailUpdateRequestError,
      emailAccountFilters,
    } = this.props;

    showHideLoading(isLoading, prevProps.isLoading);

    // Import Email Account Via CSV
    executeOnRequestStatusWithPrevStatusCheck({
      status: importEmailAccountCsvRequestStatus,
      prevStatus: prevProps.importEmailAccountCsvRequestStatus,
      onSuccess: () => {
        toaster.success(importEmailAccountCsvRequestMessage, {
          theme: Theme.New,
        });
        this.hideAddBulkImportModal();
        sendGetEmailAccountsRequest(emailAccountFilters);
      },
      onFailed: () => {
        this.hideAddBulkImportModal();
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: connectEmailAccountRequestStatus,
      prevStatus: prevProps.connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: disconnectEmailAccountRequestStatus,
      prevStatus: prevProps.disconnectEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(disconnectEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.hideDisconnectEmailAccountModal();
        sendGetEmailAccountsRequest(emailAccountFilters);
      },
      onFailed: () => {
        showToasterWithErrorCheck(disconnectEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: setDefaultEmailAccountRequestStatus,
      prevStatus: prevProps.setDefaultEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success('Email account set as default, successfully', {
          theme: Theme.New,
        });
        sendGetEmailAccountsRequest(emailAccountFilters);
      },
      onFailed: () => {
        showToasterWithErrorCheck(setDefaultEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: deleteEmailAccountRequestStatus,
      prevStatus: prevProps.deleteEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(deleteEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.hideDeleteEmailAccountModal();
        sendGetEmailAccountsRequest(emailAccountFilters);
      },
      onFailed: () => {
        showToasterWithErrorCheck(deleteEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: updateEmailAccountRequestStatus,
      prevStatus: prevProps.updateEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(updateEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.onHideEmailAccountQuotaChangeConfirmationModal();
        sendGetEmailAccountsRequest(emailAccountFilters);
      },
      onFailed: () => {
        showToasterWithErrorCheck(updateEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      prevStatus: prevProps.getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        this.showAddEmailAccountModal();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(
          getConnectedUsersAndEmailAccountsRequestError,
        );
      },
    });

    // Connect SMTP IMAP Account
    executeOnRequestStatusWithPrevStatusCheck({
      status: getConnectSmtpImapRequestStatus,
      prevStatus: prevProps.getConnectSmtpImapRequestStatus,
      onSuccess: () => {
        if (
          sendConnectSmtpImapResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendConnectSmtpImapResponse?.imapConnection?.isConnectionEstablished
        ) {
          this.hideConnectAndSaveSmtpImapAccountModel();
          toaster.success(getConnectSmtpImapRequestMessage, {
            theme: Theme.New,
          });
          sendGetEmailAccountsRequest(emailAccountFilters);
          this.updateConnectEmailProfileProgressStep();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(getConnectSmtpImapRequestMessage);
      },
    });

    // Get User Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: updateProfileProgressRequestStatus,
      prevStatus: prevProps.updateProfileProgressRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });

    // Get SMTP IMAP  Account Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendGetSmtpImapAccountDetailsRequestStatus,
      prevStatus: prevProps.sendGetSmtpImapAccountDetailsRequestStatus,
      onSuccess: () => {
        this.showConnectAndSaveSmtpImapAccountModel();
      },
      onFailed: () => {
        showToasterWithErrorCheck(t('messages.email_account_not_found'));
      },
    });

    // Update SMTP IMAP  Account Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendUpdateSmtpImapAccountRequestStatus,
      prevStatus: prevProps.sendUpdateSmtpImapAccountRequestStatus,
      onSuccess: () => {
        if (
          sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendUpdateSmtpImapAccountDetailsResponse?.imapConnection
            ?.isConnectionEstablished
        ) {
          toaster.success(sendUpdateEmailAccountRequestMessage, {
            theme: Theme.New,
          });
          this.hideConnectAndSaveSmtpImapAccountModel();
          sendGetEmailAccountsRequest(emailAccountFilters);
          resetUpdateSmtpImapAccountDetails();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(sendUpdateEmailAccountRequestMessage);
      },
    });
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendGetReactivateSubscriptionRequestStatus,
      prevStatus: prevProps.sendGetReactivateSubscriptionRequestStatus,
      onSuccess: () => {
        this.hideReactivateSubscriptionModal();
        sendGetUserSettingsRequest();
        resetReactivateSubscriptionResponse();
        toaster.success(sendGetReactivateSubscriptionResponse?.message);
      },
      onFailed: () => {
        this.hideReactivateSubscriptionModal();
        showToasterWithErrorCheck(
          sendGetReactivateSubscriptionRequestError?.message,
        );
      },
    });

    // update email account setting
    executeOnRequestStatusWithPrevStatusCheck({
      status: bulkEmailUpdateRequestStatus,
      prevStatus: prevProps.bulkEmailUpdateRequestStatus,
      onSuccess: () => {
        toaster.success(bulkEmailUpdateRequestMessage, { theme: Theme.New });
        sendGetEmailAccountsRequest(emailAccountFilters);
        this.handleResetSelected(true);
        clearEmailAccountsSelection();
      },
      onFailed: () => {
        this.handleResetSelected(true);
        clearEmailAccountsSelection();
        executeOnErrorWithErrorCheck({
          error: bulkEmailUpdateRequestError,
          onError: () => {
            toaster.error(bulkEmailUpdateRequestError?.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });

    if (
      shouldShowAddEmailAccountModal(
        showAddEmailAccountModalProp,
        prevProps.showAddEmailAccountModalProp,
      )
    ) {
      this.showAddEmailAccountModalHandler();
    }
  }

  async componentWillUnmount() {
    const { resetEmailAccountTagsRequest } = this.props;

    resetEmailAccountTagsRequest();
  }

  onHideEmailAccountQuotaChangeConfirmationModal() {
    this.setState({
      showEmailAccountQuotaChangeConfirmationModal: false,
      quotaChangeConfirmationMessage: '',
    });
    this.emailAccountSettingPayload.current = null;
  }

  onSubmitEmailAccountQuotaChangeConfirmationModal() {
    const { sendUpdateEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;

    sendUpdateEmailAccountRequest(
      actionEmailAccount.id,
      this.emailAccountSettingPayload.current,
    );
  }

  onDeleteEmailAccountModalSubmit() {
    const { sendDeleteEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;
    sendDeleteEmailAccountRequest(actionEmailAccount.id);
  }

  onDisconnectEmailAccountSubmit() {
    const { sendDisconnectEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;
    sendDisconnectEmailAccountRequest(actionEmailAccount.id);
  }

  onShowBulkEmailUpdateConfirmationModal() {
    this.setState({
      showBulkEmailAccountUpdateConfirmationModal: true,
    });
  }

  onHideBulkEmailUpdateConfirmationModal() {
    this.setState({
      showBulkEmailAccountUpdateConfirmationModal: false,
    });
  }

  onSubmitBulkEmailUpdateConfirmationModal() {
    this.setState({
      showBulkEmailAccountUpdateConfirmationModal: true,
    });
  }

  updateConnectEmailProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const connectEmailStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.ConnectEmail,
      );
      if (!connectEmailStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.ConnectEmail,
          isCompleted: true,
        });
      }
    }
  };

  reactivateSubscription = () => {
    const { sendReactivateSubscriptionRequest, customerId } = this.props;
    const { t, firstName } = this.props;

    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.reactivate_subscription_email_body',
    )}`;
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      sendReactivateSubscriptionRequest({ customerId });
    } else {
      contactAdmin(t('messages.reactivate_subscription'), emailBody);
    }
  };

  showConnectAndSaveSmtpImapAccountModel = () => {
    const { subscriptionPlan } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(5000);
      } else {
        planError(5001);
      }
    } else {
      this.setState({
        showConnectAndSaveSMTPIMAPModel: true,
        showAddEmailAccountModal: false,
      });
    }
  };

  openCSVAccountModal = () => {
    this.setState({
      showBulkModal: true,
      showAddEmailAccountModal: false,
    });
  };

  hideAddBulkImportModal = () => {
    this.setState({
      showBulkModal: false,
    });
  };

  hideBulkEmailUpdateModal = () => {
    this.setState({
      showBulkEmailUpdateModal: false,
    });
  };

  openBulkEmailUpdateModal = () => {
    this.setState({
      showBulkEmailUpdateModal: true,
    });
  };

  hideConnectAndSaveSmtpImapAccountModel = () => {
    const {
      sendConnectSmtpImapResponse,
      getConnectSmtpImapRequestMessage,
      sendGetEmailAccountsRequest,
      resetSmtpImapTestConnection,
      resetSmtpIMapConnectResponse,
      resetUpdateSmtpImapAccountDetails,
      sendUpdateSmtpImapAccountDetailsResponse,
      sendUpdateEmailAccountRequestMessage,
      emailAccountFilters,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const showAddSmtpImapAccountModal = params.get('connect-smtp');

    this.setState({
      showConnectAndSaveSMTPIMAPModel: false,
      emailAccountId: null,
    });

    if (
      !!sendConnectSmtpImapResponse?.smtpConnection?.error ||
      !!sendConnectSmtpImapResponse?.imapConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.imapConnection?.error
    ) {
      const message =
        getConnectSmtpImapRequestMessage ||
        sendUpdateEmailAccountRequestMessage;
      toaster.success(message, { theme: Theme.New });
      sendGetEmailAccountsRequest(emailAccountFilters);
    }
    resetSmtpImapTestConnection();
    resetSmtpIMapConnectResponse();
    resetUpdateSmtpImapAccountDetails();

    if (showAddSmtpImapAccountModal) {
      this.removeParamsFromUrl();
    }
  };

  onConnectSwitchHandler = (emailAccount) => {
    if (hasPermission('EMAIL.WRITE')) {
      const { sendGetSmtpImapAccountDetailsRequest } = this.props;

      if (isSavedSMTPAccount(emailAccount) || emailAccount.type === 3) {
        sendGetSmtpImapAccountDetailsRequest(emailAccount.id);
        this.setState({
          emailAccountId: emailAccount.id,
        });
      } else {
        this.addEmailAccountHandler(
          getEmailAccountType(emailAccount),
          emailAccount.id,
        );
      }
    }
  };

  redirectToGeneralSettings = (emailAccount) => {
    if (
      hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE)
    ) {
      this.goToEditEmailAccountPage({
        id: emailAccount.id,
        tab: UpdateEmailTabKeys.GENERAL_SETTINGS,
        emailAccount,
      });
    }
  };

  emailAccountTagOnClick = (emailAccount: EmailAccount) => {
    if (!emailAccount.isDefault) {
      this.makeDefaultEmailAccount(emailAccount);
    }
  };

  removeParamsFromUrl = () => {
    redirectWithoutRefresh('/settings/email-accounts');
  };

  upgradePlanWarning = () => {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      planError(2005);
    } else {
      planError(3005);
    }
  };

  onAction = (key: string, emailAccount: EmailAccount) => {
    if (key === 'edit') {
      this.redirectToGeneralSettings(emailAccount);
      return;
    }
    if (key === 'delete') {
      this.showDeleteEmailAccountModal(emailAccount);
      return;
    }
    if (key === 'setAsDefault') {
      this.emailAccountTagOnClick(emailAccount);
    }
  };

  onFiltersChange = (partialFilters: Partial<EmailAccountsFilters>) => {
    const { sendGetEmailAccountsRequest, emailAccountFilters } = this.props;

    const updatedFilters = {
      ...emailAccountFilters,
      ...partialFilters,
    };

    updateEmailAccountsFilters(updatedFilters);

    sendGetEmailAccountsRequest(updatedFilters);

    // setURLParamsForEmailAccountsFilters(updatedFilters);
  };

  onEmailAccountsSwitchChangeHandler = (emailAccount: EmailAccount) => {
    if (emailAccount.status === EmailAccountStatus.Active) {
      this.showDisconnectEmailAccountModal(emailAccount);
    } else {
      this.onConnectSwitchHandler(emailAccount);
    }
  };

  onSmtpImapModalShow = (emailAccount: EmailAccount) => {
    const { sendGetSmtpImapAccountDetailsRequest } = this.props;
    this.setState({
      emailAccountId: emailAccount.id,
    });
    sendGetSmtpImapAccountDetailsRequest(emailAccount.id);
  };

  showErrorLogModal(accountType: SMTPIMAP, errorLog: string) {
    this.setState({
      isErrorLogModalVisible: true,
      errorLogAccountType: accountType,
      errorLog,
    });
  }

  hideErrorLogModal() {
    this.setState({ isErrorLogModalVisible: false });
  }

  showDisconnectEmailAccountModal(actionEmailAccount: EmailAccount) {
    this.setState({
      showDisconnectEmailAccountModal: true,
      actionEmailAccount,
    });
  }

  hideDisconnectEmailAccountModal() {
    this.setState({
      showDisconnectEmailAccountModal: false,
      actionEmailAccount: null,
    });
  }

  hideNoSeatAvailableForEmailModal() {
    this.setState({
      showNoSeatAvailableForEmailModal: false,
    });
  }

  hideDeleteEmailAccountModal() {
    this.setState({
      showDeleteEmailAccountModal: false,
      actionEmailAccount: null,
    });
  }

  showDeleteEmailAccountModal(actionEmailAccount: EmailAccount) {
    this.setState({
      showDeleteEmailAccountModal: true,
      actionEmailAccount,
    });
  }

  hideAddEmailAccountModal() {
    const {
      showAddEmailAccountModalProp,
      handleAddEmailAccountModal,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const showAddEmailAccountModal = params.get('showAddEmailAccountModal');

    if (showAddEmailAccountModalProp) {
      handleAddEmailAccountModal(false);
    }
    this.setState({ showAddEmailAccountModal: false });

    if (showAddEmailAccountModal) {
      this.removeParamsFromUrl();
    }
  }

  showAddEmailAccountModalHandler() {
    if (hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)) {
      const {
        sendGetConnectedUsersAndEmailAccountsRequest,
        subscriptionPlan,
      } = this.props;
      if (subscriptionPlan === SubscriptionPlans.TRIAL) {
        this.showAddEmailAccountModal();
      } else {
        hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
          sendGetConnectedUsersAndEmailAccountsRequest();
      }
    }
  }

  hideReactivateSubscriptionModal() {
    this.setState({
      showReactivateSubscriptionModal: false,
    });
  }

  makeDefaultEmailAccount(actionEmailAccount: EmailAccount) {
    const { sendSetDefaultEmailAccountRequest } = this.props;
    sendSetDefaultEmailAccountRequest(actionEmailAccount.id);
  }

  addEmailAccountHandler(method: EmailAccountMethod, emailAccountId?: number) {
    const { subscriptionPlan, sendConnectEmailAccountRequest } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(5000);
      } else {
        planError(5001);
      }
    } else {
      hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
        sendConnectEmailAccountRequest(method, emailAccountId);
    }
  }

  hideRestrictionErrorModal() {
    this.setState({
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
    });
  }

  showAddEmailAccountModal() {
    const {
      subscriptionPlan,
      planType,
      totalEmailAccounts,
      slots,
      subscriptionPlanStatus,
    } = this.props;

    if (subscriptionPlan === SubscriptionPlans.FREE) {
      this.upgradePlanWarning();
    } else if (
      subscriptionPlan !== SubscriptionPlans.TRIAL &&
      Number(totalEmailAccounts) >= slots &&
      !isValueBasedPlan(planType)
    ) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (subscriptionPlanStatus === SubscriptionStatus.Canceled) {
          this.setState({ showReactivateSubscriptionModal: true });
          return;
        }
        if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
          this.setState({
            restrictionErrorModalMeta: {
              show: true,
              planName: SubscriptionPlanTitle.EmailOutreach,
            },
          });
          return;
        }
        if (
          subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
        ) {
          this.setState({
            restrictionErrorModalMeta: {
              show: true,
              planName: SubscriptionPlanTitle.EmailOutreachPro,
            },
          });
          return;
        }
      }
      this.setState({ showNoSeatAvailableForEmailModal: true });
    } else {
      this.setState({ showAddEmailAccountModal: true });
    }
  }

  goToEditEmailAccountPage({
    id,
    tab,
    emailAccount,
  }: {
    id: number;
    tab: UpdateEmailTabKeys;
    emailAccount: EmailAccount;
  }) {
    const { history } = this.props;

    if (emailAccount.status === EmailAccountStatus.InActive) {
      this.onConnectSwitchHandler(emailAccount);
    } else {
      setEmailAccountsSearchQuery(history.location.search);

      history.push({
        pathname: `/settings/email-accounts/${emailAccount.hashId}/${tab}`,
        state: {
          emailAccountId: id,
        },
      });
    }

    if (tab === UpdateEmailTabKeys.EMAIL_SETUP_SCORE) {
      setShouldRefreshEmailSetupScoreValue(emailAccount.healthScore);
    }
  }

  bannerMessageForTrailPlan() {
    const { featureUsages } = this.props;
    const shAccountEmailSendingDetails = getAccountEmailSendingDetails(
      featureUsages,
    );

    return `During trial period, you can send ${
      shAccountEmailSendingDetails.quota
    } emails and your remaining quota is ${
      +shAccountEmailSendingDetails.remainingQuota >= 0
        ? shAccountEmailSendingDetails.remainingQuota
        : '0'
    }/${shAccountEmailSendingDetails.quota}.${
      !hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
        ? ' Contact admin to upgrade'
        : ''
    }`;
  }

  handleResetSelected(resetSelected = false) {
    this.setState({ resetSelected });
  }

  render() {
    const { featureUsages } = this.props;
    const {
      showAddEmailAccountModal,
      showBulkModal,
      showDisconnectEmailAccountModal,
      showDeleteEmailAccountModal,
      showEmailAccountQuotaChangeConfirmationModal,
      quotaChangeConfirmationMessage,
      showNoSeatAvailableForEmailModal,
      showConnectAndSaveSMTPIMAPModel,
      emailAccountId,
      showReactivateSubscriptionModal,
      restrictionErrorModalMeta,
      isErrorLogModalVisible,
      errorLogAccountType,
      errorLog,

      showBulkEmailUpdateModal,
      showBulkEmailAccountUpdateConfirmationModal,
      resetSelected,
    } = this.state;
    const {
      getEmailAccountsRequestStatus,
      connectEmailAccountRequestStatus,
      updateEmailAccountRequestStatus,
      disconnectEmailAccountRequestStatus,
      deleteEmailAccountRequestStatus,
      isEmpty,
      subscriptionPlan,
      t,
      firstName,
      getTestSMTPConnectionRequestStatus,
      getTestIMAPConnectionRequestStatus,
      getConnectSmtpImapRequestStatus,
      sendUpdateSmtpImapAccountRequestStatus,
      subscriptionEndDate,
      sendGetReactivateSubscriptionRequestStatus,
      agencyConfig,
      sendBulkEmailAccountUpdateRequest,
      emailAccountFilters,
    } = this.props;

    const isDeleteEmailAccountRequestPending =
      deleteEmailAccountRequestStatus === RequestStatus.Pending;

    const isUpdateEmailAccountRequestPending =
      updateEmailAccountRequestStatus === RequestStatus.Pending;

    const isDisconnectEmailAccountRequestPending =
      disconnectEmailAccountRequestStatus === RequestStatus.Pending;

    const isConnectEmailAccountRequestPending =
      connectEmailAccountRequestStatus === RequestStatus.Pending;

    const renderTableJSX = () => {
      if (
        isEmpty &&
        !getIsRequestPending(getEmailAccountsRequestStatus) &&
        emailAccountFilters.search === ''
      ) {
        return <EmailAccountsEmptyUI />;
      }

      if (isEmpty && !getIsRequestPending(getEmailAccountsRequestStatus)) {
        return <EmailAccountsNoSearchUI />;
      }

      return (
        <EmailAccountsTable
          onAction={this.onAction}
          filters={emailAccountFilters}
          onFiltersChange={this.onFiltersChange}
          onEmailAccountsSwitchChangeHandler={
            this.onEmailAccountsSwitchChangeHandler
          }
          redirectToGeneralSettings={this.redirectToGeneralSettings}
          onSmtpImapModalShow={this.onSmtpImapModalShow}
          resetSelected={resetSelected}
          handleResetSelected={this.handleResetSelected}
        />
      );
    };

    return (
      <>
        {subscriptionPlan === SubscriptionPlans.TRIAL && (
          <Alert
            className="alert-msg"
            variant="alert"
            icon={AlertTriangle}
            description={
              <>
                {this.bannerMessageForTrailPlan()}{' '}
                <a
                  className="upgrade-a"
                  href={
                    hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
                    Routes.Setting_Billing_Upgrade_Email_Outreach_Plan
                  }
                >
                  {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
                    t('labels.upgrade_now')}
                </a>
              </>
            }
          />
        )}
        <EmailAccountsHeader />
        <GlobalSettings.Content className="email-account-container">
          <EmailAccountsActions
            onAddEmailAccount={this.showAddEmailAccountModalHandler}
            openBulkEmailUpdateModal={this.openBulkEmailUpdateModal}
          />
          {subscriptionPlan === SubscriptionPlans.FREE && (
            <HeaderBanner isEmailAccountPage={true} />
          )}
          {renderTableJSX()}
        </GlobalSettings.Content>
        <AddEmailAccountModal
          show={showAddEmailAccountModal}
          onSubmit={this.addEmailAccountHandler}
          onClose={this.hideAddEmailAccountModal}
          isRequestPending={isConnectEmailAccountRequestPending}
          t={t}
          onCSVSelect={this.openCSVAccountModal}
          onSMTPAccountSelect={this.showConnectAndSaveSmtpImapAccountModel}
        />

        <AddBulkImportModal
          show={showBulkModal}
          onCancel={this.hideAddBulkImportModal}
        />

        <DisconnectEmailAccountModal
          show={showDisconnectEmailAccountModal}
          onSubmit={this.onDisconnectEmailAccountSubmit}
          onClose={this.hideDisconnectEmailAccountModal}
          isRequestPending={isDisconnectEmailAccountRequestPending}
        />
        <DeleteEmailAccountModal
          show={showDeleteEmailAccountModal}
          onSubmit={this.onDeleteEmailAccountModalSubmit}
          onClose={this.hideDeleteEmailAccountModal}
          isRequestPending={isDeleteEmailAccountRequestPending}
        />
        <ConfirmationModal
          show={showEmailAccountQuotaChangeConfirmationModal}
          onClose={this.onHideEmailAccountQuotaChangeConfirmationModal}
          onSubmit={this.onSubmitEmailAccountQuotaChangeConfirmationModal}
          title={t('messages.make_changes_to_daily_sending_limit')}
          contents={[quotaChangeConfirmationMessage]}
          showSuccessButton={true}
          showCancelButton={true}
          successButtonText={t('labels.okay')}
          cancelButtonText={t('labels.cancel')}
          showFooterBorder={true}
          isRequestPending={isUpdateEmailAccountRequestPending}
          iconType="warning"
          showCloseButton={true}
        />
        <ConnectSMTPIMAPAccountModal
          show={showConnectAndSaveSMTPIMAPModel}
          onClose={this.hideConnectAndSaveSmtpImapAccountModel}
          isSMTPTestConnectionRequestPending={
            getTestSMTPConnectionRequestStatus === RequestStatus.Pending
          }
          isIMAPTestConnectionRequestPending={
            getTestIMAPConnectionRequestStatus === RequestStatus.Pending
          }
          isConnectAccountRequestPending={
            getConnectSmtpImapRequestStatus === RequestStatus.Pending
          }
          isUpdateSmtpImapAccountRequestPending={
            sendUpdateSmtpImapAccountRequestStatus === RequestStatus.Pending
          }
          emailAccountId={emailAccountId}
          agencyConfig={agencyConfig}
          showErrorLogModal={(accountType, eLog) =>
            this.showErrorLogModal(accountType, eLog)
          }
        />
        <ErrorLogModal
          show={isErrorLogModalVisible}
          onClose={() => this.hideErrorLogModal()}
          accountType={errorLogAccountType}
          errorLog={errorLog}
        />
        <NoSeatAvailableForEmailModal
          show={showNoSeatAvailableForEmailModal}
          onClose={this.hideNoSeatAvailableForEmailModal}
          firstName={firstName}
        />
        <ReactivateSubscriptionModal
          show={showReactivateSubscriptionModal}
          onClose={this.hideReactivateSubscriptionModal}
          onReactivateSubscription={this.reactivateSubscription}
          subscriptionEndDate={subscriptionEndDate}
          isRequestPending={
            sendGetReactivateSubscriptionRequestStatus === RequestStatus.Pending
          }
          reactivateSubscriptionModalMessage1="not_allowed_to_add_email_accounts_message_1"
          reactivateSubscriptionModalMessage2="not_allowed_to_add_email_accounts_message_2"
          reactivateSubscriptionModalHeaderMessage="no_seat_available_email_modal_title"
          submitButtonText={
            hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
              ? 'reactivate_subscription'
              : 'contact_admin'
          }
        />

        <BulkEmailUpdateModal
          show={showBulkEmailUpdateModal}
          onClose={this.hideBulkEmailUpdateModal}
          filters={emailAccountFilters}
          onShowBulkEmailUpdateConfirmationModal={
            this.onShowBulkEmailUpdateConfirmationModal
          }
          showBulkEmailAccountUpdateConfirmationModal={
            showBulkEmailAccountUpdateConfirmationModal
          }
          onHideBulkEmailUpdateConfirmationModal={
            this.onHideBulkEmailUpdateConfirmationModal
          }
          sendBulkEmailAccountUpdateRequest={sendBulkEmailAccountUpdateRequest}
          featureUsages={featureUsages}
        />

        {restrictionErrorModalMeta.show && (
          <RestrictionErrorModal
            show={restrictionErrorModalMeta.show}
            onClose={this.hideRestrictionErrorModal}
            planName={restrictionErrorModalMeta.planName}
            restrictionOn="email slots"
            feature="email accounts"
            firstName={firstName}
          />
        )}
      </>
    );
  }
}

export default EmailAccounts;
