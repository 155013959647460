import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { Adjustments, Filter, Refresh, Trash, Unlink } from '@saleshandy/icons';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import type { IProps } from './email-accounts-actions-container';

import SearchInput from '../../../../../../shared/design-system/ui/search-input';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { isAgency } from '../../../../../../shared/utils/user-details';
import AddRemoveTag from '../../../../../../shared/components/add-remove-tag';
import { RootState } from '../../../../../../store/root-reducer';
import {
  emailAccountTagsAssignRequest,
  emailAccountTagsUnassignRequest,
  getEmailAccountsRequest,
} from '../../extra-actions';
import toaster, { Theme } from '../../../../../../shared/toaster';
import EmailAccountsFiltersModal from '../email-accounts-filters-modal';
import { updateEmailAccountsFilters } from '../../email-account-slice';

const EmailAccountsActions: React.FC<IProps> = ({
  onAddEmailAccount,

  openBulkEmailUpdateModal,
  planCode,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  getConnectedUsersAndEmailAccountsRequestStatus,
  sendBulkEmailAccountUpdateRequest,
  bulkEmailUpdateRequestStatus,
  sendGetAgencyClientsDropdownRequest,
  emailAccountsFilters,
  emailAccountTagsList,
  sendGetEmailAccountsTagsRequest,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState(emailAccountsFilters.search || '');
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const selectedTags = [{ tags: emailAccountTagsList }];

  const addTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsAssignRequest.status,
  );

  const removeTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsUnassignRequest.status,
  );

  // const [clientIds, setClientIds] = useState(filters.clientIds || []);

  const openEmailAccountsFiltersModal = () => setIsFiltersModalOpen(true);
  const closeEmailAccountsFiltersModal = () => setIsFiltersModalOpen(false);

  const [
    showDisconnectEmailConfirmationPopup,
    setShowDisconnectEmailConfirmationPopup,
  ] = useState(false);
  const [
    showDeleteEmailConfirmationPopup,
    setShowDeleteEmailConfirmationPopup,
  ] = useState(false);

  const [showTagsModal, setShowTagsModal] = useState(false);

  const onSearch = () => {
    updateEmailAccountsFilters({ ...emailAccountsFilters, search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    updateEmailAccountsFilters({
      ...emailAccountsFilters,
      search: '',
      page: 1,
    });
  };

  const onHideBulkEmailDisconnectConfirmationModal = () => {
    setShowDisconnectEmailConfirmationPopup(false);
  };

  const onHideBulkEmailDeleteConfirmationModal = () => {
    setShowDeleteEmailConfirmationPopup(false);
  };

  useEffect(() => {
    sendGetEmailAccountsTagsRequest();
  }, []);

  // Initialize refs outside the array
  const bulkUpdateSettingsRef = useRef(null);
  const refreshSetupScoreRef = useRef(null);
  const disconnectEmailAccountsRef = useRef(null);
  const deleteEmailAccountsRef = useRef(null);
  const emailFiltersRef = useRef(null);
  const addRemoveTagRef = useRef(null);

  const actions = [
    (hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE) ||
      hasPermission(
        Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
      )) && {
      key: 'bulk_update_settings',
      tooltip: 'Bulk update Settings',
      ref: bulkUpdateSettingsRef,
      icon: <Adjustments height={24} width={24} />,
      onClick: () => {
        openBulkEmailUpdateModal();
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      key: 'refresh_setup_score',
      tooltip: 'Refresh Setup Score ',
      ref: refreshSetupScoreRef,
      icon: <Refresh height={24} width={24} />,
      onClick: () => {
        sendBulkEmailAccountUpdateRequest({
          refreshSetupScore: true,
          ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
            ? {
                allEmailsSelected: true,
                ignoreEmailAccountIds:
                  bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
              }
            : {
                emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
              }),
        });
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && {
      key: 'disconnect_email_accounts',
      tooltip: 'Disconnect Email Accounts',
      ref: disconnectEmailAccountsRef,
      icon: <Unlink height={24} width={24} />,
      onClick: () => {
        setShowDisconnectEmailConfirmationPopup(true);
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
      key: 'delete_email_accounts',
      tooltip: 'Delete Email Accounts',
      ref: deleteEmailAccountsRef,
      icon: <Trash height={24} width={24} />,
      onClick: () => {
        setShowDeleteEmailConfirmationPopup(true);
      },
    },
    // hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
    //   key: 'add_remove_tags',
    //   tooltip: 'Add & Remove Tags',
    //   ref: addRemoveTagRef,
    //   icon: <Tag height={24} width={24} />,
    //   onClick: () => {
    //     setShowTagsModal((prev) => !prev);
    //   },
    // },
  ].filter((opt) => opt);

  const handleDisconnectEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      disconnectEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  const handleDeleteEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      deleteEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  const handleAddTag = async (tags) => {
    const payload = {
      emailAccountIds: selectedEmailAccounts.map((email) => email.id),
      ids: [],
      newTags: tags.map((tag) => tag.name),
    };

    await dispatch(emailAccountTagsAssignRequest(payload));
    dispatch(getEmailAccountsRequest(emailAccountsFilters));
  };

  const handleRemoveTag = (tags) => {
    const payload = {
      emailAccountIds: selectedEmailAccounts
        .filter((obj) => obj.id !== undefined)
        .map((email) => email.id),
      tagIds: tags.map((tag) => tag.id),
    };

    dispatch(emailAccountTagsUnassignRequest(payload));
    dispatch(getEmailAccountsRequest(emailAccountsFilters));
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: addTagsRequestStatus,
      onSuccess: () => {
        setShowTagsModal(false);
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags applied successfully', { theme: Theme.New });
      },
    });
  }, [addTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: removeTagsRequestStatus,
      onSuccess: () => {
        setShowTagsModal(false);
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags removed successfully', { theme: Theme.New });
      },
    });
  }, [removeTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkEmailUpdateRequestStatus,
      onSuccess: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
      onFailed: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
    });
  }, [bulkEmailUpdateRequestStatus]);

  const actionClassName = classNames([
    'icon d-flex align-items-center justify-content-center',
    {
      disabled: !selectedEmailAccounts?.length,
    },
  ]);

  useEffect(() => {
    if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
      sendGetAgencyClientsDropdownRequest({});
    }
  }, []);

  const bulkUpdateLoading = getIsRequestPending(bulkEmailUpdateRequestStatus);

  const activeFilterCount = useMemo(
    () =>
      Object.values(emailAccountsFilters).reduce((count: number, filter) => {
        if (Array.isArray(filter) && filter.length > 0) {
          return count + 1; // Only count non-empty arrays
        }
        return count; // Skip other types and empty arrays
      }, 0),
    [emailAccountsFilters],
  );

  return (
    <>
      <div className="email-accounts-actions">
        <div className="d-flex align-items-center">
          <SearchInput
            search={search}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            setSearch={setSearch}
          />
          {/* {hasPermission(Permissions.CLIENT_READ) &&
            isAgency() &&
            (isAssociatedClientRequestPending ? (
              <SkeletonLoading
                height={32}
                width={187}
                className="agency-client-email-accounts"
              />
            ) : (
              <AgencyClientFilters
                clientIds={emailAccountsFilters.clientIds || []}
                onClientIdsChange={(newClientIds) =>
                  updateEmailAccountsFilters({
                    ...emailAccountsFilters,
                    clientIds: newClientIds,
                  })
                }
                btnClassName="agency-client-email-accounts btn-white"
              />
            ))} */}

          <div className="icon-container position-relative">
            <div
              className="d-flex align-items-center justify-content-center icon"
              {...accessibleOnClick(openEmailAccountsFiltersModal)}
            >
              <Filter width={24} height={24} />
            </div>

            {activeFilterCount > 0 && (
              <span className="filter-count-badge">{activeFilterCount}</span>
            )}
          </div>

          <div className="separator-line" />
          <div className="d-flex align-items-center justify-content-center email-account-icons">
            {actions.map((action) => (
              <OverlayTooltip text={t(`labels.${action.key}`)} rootClose={true}>
                <div
                  ref={action.ref}
                  className={actionClassName}
                  {...accessibleOnClick(() => {
                    if (action.ref.current) {
                      action.ref.current.blur();
                    }
                    if (selectedEmailAccounts?.length) {
                      action.onClick();
                    }
                  })}
                >
                  {action.icon}
                </div>
              </OverlayTooltip>
            ))}

            {/* <AddRemoveTag
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              selectedTags={selectedTags}
              selectedProspects={selectedEmailAccounts}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus)
              }
              leadTags={emailAccountTagsList}
              isNotRenderDropdown={true}
            /> */}
            <AddRemoveTag
              ref={addRemoveTagRef}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              tags={emailAccountTagsList}
              selectedRecords={selectedTags}
              disabled={!selectedEmailAccounts?.length}
              isShowOnlyTagIcon={true}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus)
              }
            />
          </div>
        </div>
        <div>
          {hasPermission(Permissions.EMAIL_ACCOUNT_CONNECT) && (
            <Button
              onClick={onAddEmailAccount}
              disabled={planCode === SubscriptionPlans.FREE}
              className="header-btn"
              isLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
              loadingText="Add Email Account"
            >
              Add Email Account
            </Button>
          )}
        </div>
      </div>

      {isFiltersModalOpen && (
        <EmailAccountsFiltersModal
          show={isFiltersModalOpen}
          onClose={closeEmailAccountsFiltersModal}
          emailAccountsFilters={emailAccountsFilters}
        />
      )}

      {showDisconnectEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDisconnectEmailConfirmationPopup}
          onClose={onHideBulkEmailDisconnectConfirmationModal}
          onSubmit={handleDisconnectEmailAccounts}
          title={t('labels.disconnect_email_accounts')}
          content={t('messages.disconnect_email_accounts_desc')}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          className="email-account-confirmation-popup disconnect"
          isSubmitLoading={bulkUpdateLoading}
        />
      )}
      {showDeleteEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDeleteEmailConfirmationPopup}
          onClose={onHideBulkEmailDeleteConfirmationModal}
          onSubmit={handleDeleteEmailAccounts}
          title={t('labels.delete_email_accounts')}
          content={
            <span>
              {t('messages.delete_email_message_one')}{' '}
              {t('messages.delete_email_message_two')}
              {t('messages.delete_email_message_three')}
            </span>
          }
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.TRASH_RED}
          className="email-account-confirmation-popup delete"
          isSubmitLoading={bulkUpdateLoading}
          showCloseIcon={false}
        />
      )}
    </>
  );
};

export default EmailAccountsActions;
