/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  ThemeProvider,
  ToggleButtonGroup,
  ToggleButtonRadioProps,
} from 'react-bootstrap';
import { ForwardRef } from '../../../../types/forward-ref';
import Icon from '../icon';

export type Option = {
  label?: React.ReactNode;
  value: any;
  disabled?: boolean;
};

type RadioButtonGroupProps = Omit<
  ToggleButtonRadioProps<any>,
  'value' | 'onChange' | 'name'
> & {
  options: Option[];
  value: any;
  onChange: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
};

const renderIcon = (index, totalLength) =>
  index !== totalLength - 1 ? (
    <Icon className="chevron-right-icon" identifier="chevron-right" />
  ) : (
    <></>
  );

const RadioButtonGroup = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(({ options, value, onChange, name, ...rest }, ref) => {
  const totalLength = options.length;
  return (
    <ThemeProvider prefixes={{ btn: 'btn-solid' }}>
      <ToggleButtonGroup
        {...rest}
        name={name}
        value={value}
        onChange={onChange}
        ref={ref}
      >
        {options.map((option, index) =>
          value === option.value ? (
            <>
              <label
                className="btn-solid-group active btn-solid-primary-group"
                key={index}
              >
                {option.label}
              </label>
              {renderIcon(index, totalLength)}
            </>
          ) : (
            <>
              <label
                className="btn-solid-group disabled btn-solid-primary-group"
                key={index}
              >
                {option.label}
              </label>
              {renderIcon(index, totalLength)}
            </>
          ),
        )}
      </ToggleButtonGroup>
    </ThemeProvider>
  );
}) as ForwardRef<HTMLDivElement, RadioButtonGroupProps>;

RadioButtonGroup.displayName = 'RadioButtonGroup';

export default RadioButtonGroup;
