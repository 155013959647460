/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@saleshandy/icons';
import { Button } from 'react-bootstrap';
import Modal from '../../design-system/components/atoms/modal';
import SHTag from '../images/sh-tag';
import TagAutosuggest from '../../design-system/components/molecules/tag-autosuggest';
import { RenderMode } from '../../design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { OverlayTooltip } from '../../design-system/components/overlay';
import TagAutosuggest2 from '../../design-system/components/molecules/tag-autosuggest-2';

type TagType = { id: number; name: string }; // Example Tag Type

type AddRemoveTagProps = {
  handleAddTag: (tags: TagType[]) => void;
  handleRemoveTag: (tags: TagType[]) => void;
  selectedRecords: any[];
  tags?: TagType[];
  disabled?: boolean;
  isShowOnlyTagIcon?: boolean;
  isSubmitLoading: boolean;
  isAddTagModal?: boolean;
};

const AddRemoveTag = forwardRef(
  (
    {
      handleAddTag,
      handleRemoveTag,
      disabled = false,
      selectedRecords,
      tags,
      isShowOnlyTagIcon = true,
      isSubmitLoading,
      isAddTagModal,
    }: AddRemoveTagProps,
    ref,
  ) => {
    const { t } = useTranslation();

    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [tagsToAdd, setTagsToAdd] = useState([]);
    const [tagsToRemove, setTagsToRemove] = useState([]);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

    const closeDropdown = () => setShowDropdown(false);
    const closeModal = () => {
      setTagsToAdd([]);
      setTagsToRemove([]);
      return setShowModal(false);
    };

    const isRemoveMode = modalTitle.toLowerCase() !== 'add tags';

    const toggleDropdown = () => {
      if (!disabled) setShowDropdown(!showDropdown);
    };

    const openAddTagModal = () => {
      closeDropdown();
      setModalTitle('Add Tags');
      setShowModal(true);
      setTagsToAdd([]);
      setIsApplyButtonDisabled(true);
    };

    const openRemoveTagModal = () => {
      closeDropdown();
      setModalTitle('Remove Tags');
      setShowModal(true);
      setTagsToRemove([]);
      setIsApplyButtonDisabled(false);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
      };

      // Add event listener to detect clicks outside the dropdown
      document.addEventListener('mousedown', handleClickOutside);

      // Cleanup the event listener
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (!isRemoveMode) {
        const newTagValue = tagsToAdd.some((tag) => tag.name.length > 64);
        setIsApplyButtonDisabled(tagsToAdd.length === 0 || newTagValue);
      }
    }, [tagsToAdd, isRemoveMode]);

    // Use useImperativeHandle to expose closeModal to the parent component
    useImperativeHandle(ref, () => ({
      closeModal,
      openAddTagModal,
    }));

    // Submit the modal form
    const handleSubmitModal = useCallback(() => {
      isRemoveMode ? handleRemoveTag(tagsToAdd) : handleAddTag(tagsToAdd);
    }, [isRemoveMode, handleAddTag, handleRemoveTag, tagsToAdd, tagsToRemove]);

    const generateModalTitleContent = useCallback(
      () => (
        <div className="modal-header-title">
          <SHTag />
          <div className="pl-2">{modalTitle}</div>
        </div>
      ),
      [modalTitle],
    );

    return (
      <>
        {!isAddTagModal && (
          <div className="tag-button-container">
            <OverlayTooltip text="Add or remove tags">
              {isShowOnlyTagIcon ? (
                <div
                  className={`tag-icon d-flex align-items-center justify-content-center ${
                    disabled ? 'disabled' : ''
                  }`}
                  {...accessibleOnClick(toggleDropdown)}
                >
                  <Tag
                    height="24px"
                    width="24"
                    className={disabled ? '' : ''}
                  />
                </div>
              ) : (
                <div className="d-flex align-items-center pointer">
                  <Button
                    className="tag-button"
                    onClick={toggleDropdown}
                    disabled={disabled}
                  >
                    <Tag />
                    <span>Tags</span>
                  </Button>
                </div>
              )}
            </OverlayTooltip>
            {showDropdown && !isAddTagModal && (
              <div className="add-btn-container" ref={dropdownRef}>
                <ul className="dropdown-container">
                  <li className="menu-item">
                    <div
                      className="add-tag-btn dropdown"
                      {...accessibleOnClick(openAddTagModal)}
                    >
                      <div className="p-text-tag">
                        <p className="tag-list-font menu-text">Add Tags</p>
                      </div>
                    </div>
                  </li>
                  <li className="menu-item">
                    <div
                      className="add-tag-btn dropdown"
                      {...accessibleOnClick(openRemoveTagModal)}
                    >
                      <div className="p-text-tag remove-tag-modal">
                        <p className="tag-list-font menu-text">Remove Tags</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        <Modal
          show={showModal && !disabled}
          className="add-remove-tag-modal"
          titleContent={generateModalTitleContent()}
          onClose={closeModal}
          submitButtonText={t('labels.apply')}
          isSubmitDisabled={isApplyButtonDisabled}
          isSubmitLoading={isSubmitLoading}
          onSubmit={handleSubmitModal}
          backdrop="static"
          submitButtonClassName="header-btn"
          cancelButtonClassName="cancel-btn"
          extraModalProps={{
            enforceFocus: false,
            'aria-labelledby': 'contained-modal-title-vcenter',
          }}
        >
          <div
            className={`saved-tab-tags ${isRemoveMode && 'remove-tag-modal'}`}
          >
            <TagAutosuggest2
              selectedProspects={[]}
              showCreateSuggestion={!isRemoveMode}
              defaultSuggestionOpened={isRemoveMode}
              restrictExistingOnly={isRemoveMode}
              tagsToAdd={tagsToAdd}
              tags={tags}
              setTagsToAdd={(data) => {
                setTagsToAdd(data);
              }}
              tagsToRemove={tagsToRemove}
              setTagsToRemove={setTagsToRemove}
              renderMode={RenderMode.Inline}
              showAddedTags={false}
            />
          </div>
        </Modal>
      </>
    );
  },
);

export default AddRemoveTag;
