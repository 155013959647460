import {
  EmailInboxFilters,
  EmailListItem,
  EmailThreadItem,
  UpdateUniboxEmailScheduleDataPayload,
} from '../type';
import { getDefaultFilters } from './utils/get-default-filters';
import { getIsRequestPending } from '../../../shared/utils/get-request-status';
import {
  SELECT_ALL_EMAIL_THREADS_LIMIT,
  UnifiedInboxFeed,
} from '../enum/unified-inbox.enum';
import { TeamsAndUsersList } from '../../settings/types/users-and-teams';
import moment from 'moment-timezone';
import { ConversationType } from '../../prospect/enums/prospect';

/* Max Days Range for Filter */
export const MAX_DAYS_RANGE = 90;

/* Function to check whether the filters are modified or not.
It returns true, if the filters has been modified, else return false  */
export const checkIsFilterModified = (filter: EmailInboxFilters) =>
  JSON.stringify(filter) !== JSON.stringify(getDefaultFilters());

/* Function to check is the filters get API has been succeeded or not,
  It will be used to determine, weather to show or hide loading state UI  */
export const getIsFilterLoading = ({
  getEmailCategoriesRequestStatus,
  getEmailAccountsListForFilterRequestStatus,
  getSequencesListForFilterRequestStatus,
  getAgencyClientsDropdownRequestStatus,
  getTeamsAndMembersListRequestStatus,
}) => ({
  agencyClientFilterLoading: getIsRequestPending(
    getAgencyClientsDropdownRequestStatus,
  ),
  sequencesFilterLoading: getIsRequestPending(
    getSequencesListForFilterRequestStatus,
  ),
  emailAccountsFilterLoading: getIsRequestPending(
    getEmailAccountsListForFilterRequestStatus,
  ),
  categoriesFilterLoading: getIsRequestPending(getEmailCategoriesRequestStatus),
  ownersFilterLoading: getIsRequestPending(getTeamsAndMembersListRequestStatus),
});

export const getUnifiedInboxPathName = (hashId: string): string =>
  hashId ? `/conversations/${hashId}` : '/conversations';

export const getTotalSelectedEmailCount = (totalItems: number) =>
  totalItems > SELECT_ALL_EMAIL_THREADS_LIMIT
    ? SELECT_ALL_EMAIL_THREADS_LIMIT
    : totalItems;

export const getAppliedFiltersCount = (
  unifiedInboxFilters: EmailInboxFilters,
): number => {
  const filterKeys: (keyof EmailInboxFilters)[] = [
    'conversationType',
    'sequences',
    'clients',
    'emailAccounts',
    'outcomes',
    'owners',
    'date',
  ];

  return filterKeys.reduce((count, key) => {
    const filterValue = unifiedInboxFilters[key];
    if (Array.isArray(filterValue) && filterValue.length > 0) {
      count += 1;
    }
    if (
      key === 'conversationType' &&
      filterValue === ConversationType.External
    ) {
      count += 1;
    }
    return count;
  }, 0);
};

export const updateUserIdsWithNames = (
  ids: {
    id: number;
    firstName: string;
  }[],
  teams: TeamsAndUsersList[],
) => {
  const idMap = new Map();

  // Populate the idMap with the first matching user names from the teams array
  teams.forEach((team) => {
    team.users.forEach((user) => {
      if (!idMap.has(user.id)) {
        idMap.set(user.id, user.userName);
      }
    });
  });

  // Update the ids array with names from the idMap
  return ids.map((item) => {
    if (idMap.has(item.id)) {
      return {
        ...item,
        firstName: idMap.get(item.id),
      };
    }
    return item;
  });
};

export const getIsFeedDraft = (uniboxFiltersFeed: UnifiedInboxFeed) => {
  return uniboxFiltersFeed === UnifiedInboxFeed.Draft;
};

export const getSnippet = (content: string): string => {
  let textContent = content.substring(0, 1000);
  textContent = textContent.replace(/<head>.*<\/head>/s, '').trim();
  textContent = textContent.replace(/<\/?[^>]+(>|$)/g, ' ').trim();
  textContent = textContent.replace(/\s+/g, ' ');
  textContent = textContent.substring(0, 60);
  return textContent;
};

/**
 * This function will go through emailsList and emailsThreadList
 * and will process changes only for emailList item matching payload.hashId
 * and then will process emailsThreadsList to match backend's behaviour
 * which return values related to relavant draft/schedule
 * and add it to current emailList item
 */
export const getUniboxEmailListWithUpdatedScheduleData = (
  emailsList: EmailListItem[],
  emailThreadList: EmailThreadItem[],
  payload: UpdateUniboxEmailScheduleDataPayload,
): EmailListItem[] => {
  return emailsList.map((email) => {
    if (email.hashId === payload.hashId) {
      let relavantUnifiedScheduleEmail: EmailThreadItem;
      //Get changes from right side conversation
      emailThreadList.forEach((emailThread) => {
        if (emailThread.unifiedScheduled) {
          //Only using emailThreads that has unifiedScheduled object
          if (payload.isDraftTab) {
            if (!emailThread.unifiedScheduled?.scheduledAt) {
              //Only using emailThreads that does not have scheduledAt
              relavantUnifiedScheduleEmail = emailThread;
            }
          } else {
            //Only using emailThreads that has unifiedScheduled object
            if (!relavantUnifiedScheduleEmail) {
              //If None is selected
              relavantUnifiedScheduleEmail = emailThread;
            } else if (
              !relavantUnifiedScheduleEmail.unifiedScheduled?.scheduledAt
            ) {
              //If Selected Object is draft (always update)
              relavantUnifiedScheduleEmail = emailThread;
            } else {
              //If Selected Object is schedule
              if (emailThread.unifiedScheduled.scheduledAt) {
                //Current Item is schedule
                if (
                  moment(
                    emailThread.unifiedScheduled.scheduledAt,
                  ).isSameOrBefore(
                    relavantUnifiedScheduleEmail.unifiedScheduled.scheduledAt,
                  )
                ) {
                  //If Current Schedule is Before Selected Schedule
                  relavantUnifiedScheduleEmail = emailThread;
                }
              }
            }
          }
        }
      });
      //Update them accordingly in left side
      return {
        ...email,
        status: relavantUnifiedScheduleEmail
          ? relavantUnifiedScheduleEmail.unifiedScheduled.status
          : null,
        scheduledAt: relavantUnifiedScheduleEmail
          ? relavantUnifiedScheduleEmail.unifiedScheduled.scheduledAt
          : null,
        content: relavantUnifiedScheduleEmail
          ? getSnippet(
              relavantUnifiedScheduleEmail.unifiedScheduled?.content ?? '',
            )
          : getSnippet(
              emailThreadList[emailThreadList.length - 1]?.content ?? '',
            ),
        timezone: relavantUnifiedScheduleEmail
          ? relavantUnifiedScheduleEmail.unifiedScheduled.timezone
          : null,
        failedReason: relavantUnifiedScheduleEmail
          ? relavantUnifiedScheduleEmail.unifiedScheduled.failedReason
          : null,
      };
    }
    return email;
  });
};
