export enum Permissions {
  // Sequence
  ACCOUNT_SEQUENCE_READ = 'ACCOUNT.SEQUENCE.READ',
  ACCOUNT_SEQUENCE_WRITE = 'ACCOUNT.SEQUENCE.WRITE',
  ACCOUNT_SEQUENCE_UPDATE = 'ACCOUNT.SEQUENCE.UPDATE',
  ACCOUNT_SEQUENCE_DELETE = 'ACCOUNT.SEQUENCE.DELETE',
  SEQUENCE_READ = 'SEQUENCE.READ',
  SEQUENCE_PROSPECT_READ = 'SEQUENCE_PROSPECT.READ',
  SEQUENCE_EMAIL_READ = 'SEQUENCE_EMAIL.READ',
  SEQUENCE_SCORE_READ = 'SEQUENCE_SCORE.READ',
  SEQUENCE_SETTING_READ = 'SEQUENCE_SETTING.READ',
  SEQUENCE_WRITE = 'SEQUENCE.WRITE',
  SEQUENCE_DELETE = 'SEQUENCE.DELETE',
  SEQUENCE_UPDATE = 'SEQUENCE.UPDATE',
  DRAFT_SEQUENCE_READ = 'DRAFT_SEQUENCE.READ',

  // Sequence Step
  ACCOUNT_SEQUENCE_STEP_READ = 'ACCOUNT.SEQUENCE_STEP.READ',
  ACCOUNT_SEQUENCE_STEP_WRITE = 'ACCOUNT.SEQUENCE_STEP.WRITE',
  ACCOUNT_SEQUENCE_STEP_UPDATE = 'ACCOUNT.SEQUENCE_STEP.UPDATE',
  ACCOUNT_SEQUENCE_STEP_DELETE = 'ACCOUNT.SEQUENCE_STEP.DELETE',
  SEQUENCE_STEP_READ = 'SEQUENCE_STEP.READ',
  SEQUENCE_STEP_WRITE = 'SEQUENCE_STEP.WRITE',
  SEQUENCE_STEP_UPDATE = 'SEQUENCE_STEP.UPDATE',
  SEQUENCE_STEP_DELETE = 'SEQUENCE_STEP.DELETE',

  // Sequence Step Variant
  ACCOUNT_SEQUENCE_STEP_VARIANT_READ = 'ACCOUNT.SEQUENCE_STEP_VARIANT.READ',
  ACCOUNT_SEQUENCE_STEP_VARIANT_WRITE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.WRITE',
  ACCOUNT_SEQUENCE_STEP_VARIANT_UPDATE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.UPDATE',
  ACCOUNT_SEQUENCE_STEP_VARIANT_DELETE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.DELETE',
  SEQUENCE_STEP_VARIANT_READ = 'SEQUENCE_STEP_VARIANT.READ',
  SEQUENCE_STEP_VARIANT_WRITE = 'SEQUENCE_STEP_VARIANT.WRITE',
  SEQUENCE_STEP_VARIANT_UPDATE = 'SEQUENCE_STEP_VARIANT.UPDATE',
  SEQUENCE_STEP_VARIANT_DELETE = 'SEQUENCE_STEP_VARIANT.DELETE',
  SEQUENCE_STEP_RELATIVE_DAYS_READ = 'SEQUENCE_STEP.RELATIVE_DAYS.READ',
  SEQUENCE_STEP_RELATIVE_DAYS_UPDATE = 'SEQUENCE_STEP.RELATIVE_DAYS.UPDATE',

  // Sequence Prospect
  SEQUENCE_PROSPECT_EXPORT = 'SEQUENCE_PROSPECT.EXPORT',
  SEQUENCE_PROSPECT_ADD_TO_SEQUENCE = 'SEQUENCE_PROSPECT.ADD_TO_SEQUENCE',
  SEQUENCE_PROSPECT_UPDATE_TAGS = 'SEQUENCE_PROSPECT.UPDATE_TAGS',
  SEQUENCE_PROSPECT_REMOVE = 'SEQUENCE_PROSPECT.REMOVE',
  SEQUENCE_PROSPECT_STATUS_REPLIED = 'SEQUENCE_PROSPECT.STATUS.REPLIED',
  SEQUENCE_PROSPECT_STATUS_UNSUBSCRIBE = 'SEQUENCE_PROSPECT.STATUS.UNSUBSCRIBE',
  SEQUENCE_PROSPECT_STATUS_FINISHED = 'SEQUENCE_PROSPECT.STATUS.FINISHED',
  SEQUENCE_PROSPECT_STATUS_PAUSED = 'SEQUENCE_PROSPECT.STATUS.PAUSED',
  SEQUENCE_PROSPECT_STATUS_RESUMED = 'SEQUENCE_PROSPECT.STATUS.RESUMED',
  SEQUENCE_PROSPECT_OUTCOME_UPDATE = 'SEQUENCE_PROSPECT.OUTCOME.UPDATE',
  SEQUENCE_PROSPECT_PROFILE = 'SEQUENCE_PROSPECT.PROFILE',
  SEQUENCE_PROSPECT_ACTIVITY = 'SEQUENCE_PROSPECT.ACTIVITY',
  SEQUENCE_PROSPECT_SEQUENCES = 'SEQUENCE_PROSPECT.SEQUENCES',

  // Sequence Settings
  SEQUENCE_SETTING_UPDATE = 'SEQUENCE_SETTING.UPDATE',
  SEQUENCE_SETTING_EMAIL_ACCOUNT_UPDATE = 'SEQUENCE_SETTING.EMAIL_ACCOUNT.UPDATE',

  // Task
  TASK_READ = 'TASK.READ',

  // Custom Domain
  ACCOUNT_CUSTOM_DOMAIN_READ = 'ACCOUNT.CUSTOM_DOMAIN.READ',
  ACCOUNT_CUSTOM_DOMAIN_WRITE = 'ACCOUNT.CUSTOM_DOMAIN.WRITE',
  ACCOUNT_CUSTOM_DOMAIN_UPDATE = 'ACCOUNT.CUSTOM_DOMAIN.UPDATE',
  ACCOUNT_CUSTOM_DOMAIN_DELETE = 'ACCOUNT.CUSTOM_DOMAIN.DELETE',
  CUSTOM_DOMAIN_SHOW = 'CUSTOM_DOMAIN.SHOW',
  CUSTOM_DOMAIN_READ = 'CUSTOM_DOMAIN.READ',
  CUSTOM_DOMAIN_WRITE = 'CUSTOM_DOMAIN.WRITE',
  CUSTOM_DOMAIN_UPDATE = 'CUSTOM_DOMAIN.UPDATE',
  CUSTOM_DOMAIN_DELETE = 'CUSTOM_DOMAIN.DELETE',
  ACCOUNT_CUSTOM_DOMAIN_EMAIL_ACCOUNT_READ = 'ACCOUNT.CUSTOM_DOMAIN_EMAIL_ACCOUNT.READ',
  ACCOUNT_CUSTOM_DOMAIN_EMAIL_ACCOUNT_UPDATE = 'ACCOUNT.CUSTOM_DOMAIN_EMAIL_ACCOUNT.READ',

  // Schedule
  ACCOUNT_SCHEDULE_READ = 'ACCOUNT.SCHEDULE.READ',
  ACCOUNT_SCHEDULE_WRITE = 'ACCOUNT.SCHEDULE.WRITE',
  ACCOUNT_SCHEDULE_DELETE = 'ACCOUNT.SCHEDULE.DELETE',
  ACCOUNT_SCHEDULE_UPDATE = 'ACCOUNT.SCHEDULE.UPDATE',
  SCHEDULE_READ = 'SCHEDULE.READ',
  SCHEDULE_WRITE = 'SCHEDULE.WRITE',
  SCHEDULE_DELETE = 'SCHEDULE.DELETE',
  SCHEDULE_UPDATE = 'SCHEDULE.UPDATE',
  SCHEDULE_SHOW = 'SCHEDULE.SHOW',

  // User
  ACCOUNT_USER_READ = 'ACCOUNT.USER.READ',
  ACCOUNT_USER_INVITE = 'ACCOUNT.USER.INVITE',
  ACCOUNT_USER_DELETE = 'ACCOUNT.USER.DELETE',
  ACCOUNT_USER_UPDATE = 'ACCOUNT.USER.UPDATE',
  USER_READ = 'USER.READ',

  // Users And Teams
  ACCOUNT_TEAM_INVITE_WRITE = 'ACCOUNT.TEAM_INVITE.WRITE',
  ACCOUNT_TEAM_MEMBER_READ = 'ACCOUNT.TEAM_MEMBER.READ',
  ACCOUNT_TEAM_WRITE = 'ACCOUNT.TEAM.WRITE',
  ACCOUNT_TEAM_UPDATE = 'ACCOUNT.TEAM.UPDATE',
  ACCOUNT_TEAM_DELETE = 'ACCOUNT.TEAM.DELETE',
  TEAM_MEMBER_READ = 'TEAM_MEMBER.READ',
  TEAM_TEAM_MEMBER_READ = 'TEAM.TEAM_MEMBER.READ',
  ACCOUNT_TEAM_READ = 'ACCOUNT.TEAM.READ',
  TEAM_USER_READ = 'TEAM.USER.READ',
  TEAM_SHOW = 'TEAM.SHOW',
  USER_TEAM_SHOW = 'USER.TEAM.SHOW',

  // Prospect
  ACCOUNT_PROSPECT_READ = 'ACCOUNT.PROSPECT.READ',
  ACCOUNT_PROSPECT_WRITE = 'ACCOUNT.PROSPECT.WRITE',
  ACCOUNT_PROSPECT_UPDATE = 'ACCOUNT.PROSPECT.UPDATE',
  ACCOUNT_PROSPECT_DELETE = 'ACCOUNT.PROSPECT.DELETE',
  PROSPECT_READ = 'PROSPECT.READ',
  PROSPECT_WRITE = 'PROSPECT.WRITE',
  PROSPECT_UPDATE = 'PROSPECT.UPDATE',
  PROSPECT_DELETE = 'PROSPECT.DELETE',

  // Fields
  ACCOUNT_FIELD_READ = 'ACCOUNT.FIELD.READ',
  ACCOUNT_FIELD_WRITE = 'ACCOUNT.FIELD.WRITE',
  ACCOUNT_FIELD_UPDATE = 'ACCOUNT.FIELD.UPDATE',
  ACCOUNT_FIELD_DELETE = 'ACCOUNT.FIELD.DELETE',
  FIELD_SHOW = 'FIELD.SHOW',
  FIELD_READ = 'FIELD.READ',
  FIELD_WRITE = 'FIELD.WRITE',
  FIELD_UPDATE = 'FIELD.UPDATE',
  FIELD_DELETE = 'FIELD.DELETE',

  // Outcomes
  ACCOUNT_OUTCOME_READ = 'ACCOUNT.OUTCOME.READ',
  ACCOUNT_OUTCOME_WRITE = 'ACCOUNT.OUTCOME.WRITE',
  ACCOUNT_OUTCOME_UPDATE = 'ACCOUNT.OUTCOME.UPDATE',
  ACCOUNT_OUTCOME_DELETE = 'ACCOUNT.OUTCOME.DELETE',
  OUTCOME_SHOW = 'OUTCOME.SHOW',
  OUTCOME_READ = 'OUTCOME.READ',
  OUTCOME_WRITE = 'OUTCOME.WRITE',
  OUTCOME_UPDATE = 'OUTCOME.UPDATE',
  OUTCOME_DELETE = 'OUTCOME.DELETE',

  // TEMPLATE
  ACCOUNT_TEMPLATE_READ = 'ACCOUNT.TEMPLATE.READ',
  ACCOUNT_TEMPLATE_WRITE = 'ACCOUNT.TEMPLATE.WRITE',
  ACCOUNT_TEMPLATE_UPDATE = 'ACCOUNT.TEMPLATE.UPDATE',
  ACCOUNT_TEMPLATE_DELETE = 'ACCOUNT.TEMPLATE.DELETE',
  TEMPLATE_READ = 'TEMPLATE.READ',
  TEMPLATE_WRITE = 'TEMPLATE.WRITE',
  TEMPLATE_UPDATE = 'TEMPLATE.UPDATE',
  TEMPLATE_DELETE = 'TEMPLATE.DELETE',
  TEMPLATE_SHARE = 'TEMPLATE.SHARE',
  MY_TEMPLATES_READ = 'MY_TEMPLATES.READ',
  TEAM_TEMPLATES_READ = 'TEAM_TEMPLATES.READ',

  // Report
  REPORT_READ = 'REPORT.READ',
  ACCOUNT_TEAM_REPORT_SHOW = 'ACCOUNT.TEAM_REPORT.SHOW',
  ACCOUNT_TEAM_REPORT_READ = 'ACCOUNT.TEAM_REPORT.READ',
  TEAM_TEAM_REPORT_READ = 'TEAM.TEAM_REPORT.READ',

  // EMAIL
  ACCOUNT_EMAIL_READ = 'ACCOUNT.EMAIL.READ',
  ACCOUNT_EMAIL_WRITE = 'ACCOUNT.EMAIL.WRITE',
  ACCOUNT_EMAIL_UPDATE = 'ACCOUNT.EMAIL.UPDATE',
  ACCOUNT_EMAIL_DELETE = 'ACCOUNT.EMAIL.DELETE',
  EMAIL_READ = 'EMAIL.READ',
  EMAIL_WRITE = 'EMAIL.WRITE',
  EMAIL_UPDATE = 'EMAIL.UPDATE',
  EMAIL_DELETE = 'EMAIL.DELETE',
  MAILBOX_EMAIL_READ = 'MAILBOX_EMAIL.READ',
  EMAIL_WARMUP_READ = 'EMAIL.WARMUP.READ',
  EMAIL_WARMUP_SETTINGS_UPDATE = 'EMAIL.WARMUP_SETTINGS.UPDATE',
  EMAIL_ACCOUNT_READ = 'EMAIL_ACCOUNT.READ',
  EMAIL_ACCOUNT_CONNECT = 'EMAIL_ACCOUNT.CONNECT',
  EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE = 'EMAIL_ACCOUNT_SETTINGS.GENERAL_SETTING.UPDATE',
  EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE = 'EMAIL_ACCOUNT_SETTINGS.SENDING_SETTING.UPDATE',
  EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE = 'EMAIL_ACCOUNT_SETTINGS.SETUP_SCORE',
  EMAIL_ACCOUNT_DELETE = 'EMAIL_ACCOUNT.DELETE',
  EMAIL_ACCOUNT_DISCONNECT = 'EMAIL_ACCOUNT.DISCONNECT',
  EMAIL_ACCOUNT_SET_AS_DEFAULT = 'EMAIL_ACCOUNT.SET_AS_DEFAULT',
  EMAIL_ACCOUNT_TAGS = 'EMAIL_ACCOUNT.TAGS',

  // Subscription
  ACCOUNT_SUBSCRIPTION_CREATE = 'ACCOUNT.SUBSCRIPTION.CREATE',
  ACCOUNT_SUBSCRIPTION_UPDATE = 'ACCOUNT.SUBSCRIPTION.UPDATE',
  ACCOUNT_SUBSCRIPTION_READ = 'ACCOUNT.SUBSCRIPTION.READ',
  BILLING_SHOW = 'BILLING.SHOW',

  // Blacklist Domains
  ACCOUNT_BLACKLISTED_DOMAIN_WRITE = 'ACCOUNT.BLACKLISTED_DOMAIN.WRITE',
  ACCOUNT_BLACKLISTED_DOMAIN_READ = 'ACCOUNT.BLACKLISTED_DOMAIN.READ',
  ACCOUNT_BLACKLISTED_DOMAIN_DELETE = 'ACCOUNT.BLACKLISTED_DOMAIN.DELETE',

  // Api Token
  ACCOUNT_API_TOKEN_WRITE = 'ACCOUNT.API_TOKEN.WRITE',
  ACCOUNT_API_TOKEN_READ = 'ACCOUNT.API_TOKEN.READ',
  ACCOUNT_API_TOKEN_DELETE = 'ACCOUNT.API_TOKEN.DELETE',
  API_TOKEN_SHOW = 'API_TOKEN.SHOW',
  API_TOKEN_WRITE = 'API_TOKEN.WRITE',
  API_TOKEN_READ = 'API_TOKEN.READ',
  API_TOKEN_DELETE = 'API_TOKEN.DELETE',

  // Notifications
  NOTIFICATION_READ = 'NOTIFICATION.READ',
  NOTIFICATION_UPDATE = 'NOTIFICATION.UPDATE',

  // EV-Credits
  ACCOUNT_EV_CREDIT_WRITE = 'ACCOUNT.EV_CREDIT.WRITE',
  EV_CREDIT_READ = 'EV_CREDIT.READ',

  // My-Profile
  MY_PROFILE_READ = 'MY_PROFILE.READ',
  MY_PROFILE_UPDATE = 'MY_PROFILE.UPDATE',
  CREDENTIALS_UPDATE = 'CREDENTIALS.UPDATE',
  ACCOUNT_PROFILE_DANGER_ZONE = 'ACCOUNT.PROFILE.DANGERZONE',
  ACCOUNT_DATA_DELETE = 'ACCOUNT.DATA.DELETE',

  // Admin-Settings
  ADMIN_SETTING_READ = 'ADMIN_SETTING.READ',

  // Setting
  SETTING_READ = 'SETTING.READ',

  // Webhook
  WEBHOOKS_SHOW = 'WEBHOOKS.SHOW',
  WEBHOOKS_READ = 'WEBHOOKS.READ',
  WEBHOOKS_WRITE = 'WEBHOOKS.WRITE',

  // Chrome-Extentation
  CHROME_EXTENSION_READ = 'CHROME_EXTENSION.READ',

  // Agency
  AGENCY_PORTAL_READ = 'AGENCY.PORTAL.READ',
  AGENCY_USER_READ = 'AGENCY.USER.READ',
  AGENCY_CLIENT_ASSIGN = 'AGENCY.CLIENT.ASSIGN',
  AGENCY_USER_UPDATE = 'AGENCY.USER.UPDATE',
  AGENCY_CLIENT_UPDATE = 'AGENCY.CLIENT.UPDATE',
  AGENCY_CLIENT_READ = 'AGENCY.CLIENT.READ',
  AGENCY_CLIENT_CREATE = 'AGENCY.CLIENT.CREATE',
  ACCOUNT_ADMIN_CREATE = 'ACCOUNT.ADMIN.CREATE',
  AGENCY_USER_INVITE = 'AGENCY.USER.INVITE',
  AGENCY_CLIENT_DELETE = 'AGENCY.CLIENT.DELETE',
  AGENCY_USER_DELETE = 'AGENCY.USER.DELETE',
  ACCOUNT_CLIENT_READ = 'ACCOUNT.CLIENT.READ',
  AGENCY_ADMIN_UPDATE = 'AGENCY.ADMIN.UPDATE',

  // Unified Inbox
  UNIFIED_INBOX_READ = 'UNIBOX_THREAD.READ',
  UNIFIED_INBOX_REPLY = 'UNIBOX.REPLY',
  UNIFIED_INBOX_FORWARD = 'UNIBOX.FORWARD',
  UNIFIED_INBOX_THREAD_UNLIMITED = 'UNIBOX_THREAD.UNLIMITED',
  UNIFIED_INBOX_THREAD_FILTER = 'UNIBOX_THREAD.FILTER',
  UNIBOX_READ_STATUS_UPDATE = 'UNIBOX_READ_STATUS.UPDATE',
  UNIBOX_CATEGORY_UPDATE = 'UNIBOX_CATEGORY.UPDATE',
  UNIBOX_EMAIL_THREAD_DELETE = 'UNIBOX_EMAIL_THREAD.DELETE',

  // Integrations
  INTEGRATION_READ = 'INTEGRATION.READ',
  INTEGRATION_WRITE = 'INTEGRATION.WRITE',
  // Lead Finder
  LEAD_FINDER_READ = 'LEAD_FINDER.READ',
  LEAD_FINDER_REVEAL = 'LEAD_FINDER.REVEAL',

  // Lead Finder - Search Leads
  LEAD_FINDER_SEARCH_READ = 'LEAD_FINDER.SEARCH.READ',

  // Lead Finder - Filters
  LEAD_FINDER_FILTER_READ = 'LEAD_FINDER.FILTER.READ',
  LEAD_FINDER_FILTER_WRITE = 'LEAD_FINDER.FILTER.WRITE',
  LEAD_FINDER_FILTER_UPDATE = 'LEAD_FINDER.FILTER.UPDATE',
  LEAD_FINDER_FILTER_DELETE = 'LEAD_FINDER.FILTER.DELETE',

  // Lead Finder - Filter Suggestion
  LEAD_FINDER_FILTER_SUGGESTION_READ = 'LEAD_FINDER.FILTER.SUGGESTION.READ',

  // Lead Finder - Subscription
  LEAD_FINDER_SUBSCRIPTION_READ = 'LEAD_FINDER.SUBSCRIPTION.READ',
  LEAD_FINDER_SUBSCRIPTION_WRITE = 'LEAD_FINDER.SUBSCRIPTION.WRITE',
  LEAD_FINDER_SUBSCRIPTION_UPDATE = 'LEAD_FINDER.SUBSCRIPTION.UPDATE',
  LEAD_FINDER_SUBSCRIPTION_DELETE = 'LEAD_FINDER.SUBSCRIPTION.DELETE',

  // Agency Client Management
  CLIENT_READ = 'CLIENT_AGENCY.READ',
  CLIENT_WRITE = 'CLIENT_AGENCY.WRITE',
  CLIENT_UPDATE = 'CLIENT_AGENCY.UPDATE',
  CLIENT_DELETE = 'CLIENT_AGENCY.DELETE',
  WHITELABEL_SHOW = 'WHITELABEL.SHOW',
  WHITELABEL_ACCESS = 'WHITELABEL.ACCESS',

  // OOO
  OOO_SETTING_READ = 'OOO.SETTING.READ',

  // DNC
  DNC_READ = 'DNC.READ',
  DNC_WRITE = 'DNC.WRITE',
  DNC_UPDATE = 'DNC.UPDATE',
  DNC_DELETE = 'DNC.DELETE',
  DNC_LIST_READ = 'DNC_LIST.READ',
  DNC_LIST_WRITE = 'DNC_LIST.WRITE',
  DNC_LIST_DELETE = 'DNC_LIST.DELETE',

  REFER_TAB_SHOW = 'REFER.TAB.SHOW',
  GROWTH_HUB_TAB_SHOW = 'GROWTH_HUB.TAB.SHOW',
  CHAT_SUPPORT_SHOW = 'CHAT_SUPPORT.SHOW',
  MASTER_CLASS_SHOW = 'MASTER_CLASS.SHOW',
  WHATSANEW_SHOW = 'WHATSANEW.SHOW',
  SCHEDULE_DEMO_SHOW = 'SCHEDULE.DEMO.SHOW',
  FEATURE_REQUEST_SHOW = 'FEATURE_REQUEST.SHOW',
  BECOME_OUR_AFFILATE_SHOW = 'BECOME_OUR_AFFILATE.SHOW',
  HELP_SHOW = 'HELP.SHOW',
}
