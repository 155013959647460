import { EmailAccountType } from '../enums/email-account';
import { EmailAccountSettings } from './email-account-settings';
import { CustomDomainType } from './custom-domain';

type CredentialPayload = {
  emailAddress: string;
  password: string;
  host: string;
  port: number;
};

type SmtpImapConnectionPayload = {
  id: number;
  emailAccount: number;
  smtpCredentialPayload: CredentialPayload;
  imapCredentialPayload: CredentialPayload;
  smtpConnectionErrorDetails: string | null;
  imapConnectionErrorDetails: string | null;
};

export enum EmailAccountConnectionStatus {
  Active = 'active',
  InActive = 'inActive',
  InProgress = 'inProgress',
}

export enum EmailAccountStatus {
  InActive = 0,
  Active = 1,
  InProgress = 2,
}

export type EmailAccount = {
  id?: number;
  user?: any;
  fromName?: string;
  fromFirstName?: string;
  fromLastName?: string;
  fromEmail?: string;
  type?: EmailAccountType;
  status?: EmailAccountStatus;
  isDefault?: number;
  lastConnectedAt?: Date;
  createdAt?: Date | string;
  modifiedAt?: Date | string;
  settings?: EmailAccountSettings[];
  emailAccountCustomDomain?: EmailAccountCustomDomain;
  healthScore: number;
  emailServiceProvider?: string;
  smtpImapConnectionPayload?: SmtpImapConnectionPayload;
  hashId: string;
  sequences: {
    id: string;
    title: string;
  }[];
  tags: number[];
};

export type EmailAccountCustomDomain = {
  id: number;
  userCustomDomain: CustomDomainType;
};
