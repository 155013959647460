import React from 'react';

const EmailVerificationError = ({ availableCredits, requiredCredits }) => {
  const accountCreditData = [
    {
      key: 'Current available',
      value: availableCredits,
    },
    {
      key: 'Required',
      value: requiredCredits,
    },
  ];
  return (
    <>
      <div className="email-verification-error-container">
        <div className="email-verification-error-content">
          <tbody className="email-verification-error-table">
            {accountCreditData.map((item) => (
              <tr className="credit-table">
                <td className="regular-1 left-content">{item.key}</td>
                <td className="semibold-1 right-content">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationError;
