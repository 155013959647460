import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import { SortOrder } from 'react-bootstrap-table-next';

import { EmailAccount } from '../../../../../types/email-account';
import { EmailAccountsSortBy } from '../../../../../enums/email-account';
import { Column } from '../../../../../../../shared/design-system/components/organisms/table/types';

import EmailAccountSwitch from '../fields/email-account-switch';
import SenderNameField from '../fields/sender-name-field';
import SetupScoreField from '../fields/setup-score-field';
import ActiveSequencesField from '../fields/active-sequences-field';
import EmailSentField from '../fields/email-sent-field';
import AddedByField from '../fields/added-by-field';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import ProspectTagsRow from '../../../../../../../shared/design-system/components/molecules/prospect-tags-row';

export const getEmailAccountsColumns = ({
  itemCount,
  onSort,
  onEmailAccountsSwitchChangeHandler,
  redirectToGeneralSettings,
  onSmtpImapModalShow,
}: {
  itemCount: number;
  onSort: (sortBy: EmailAccountsSortBy, order: SortOrder) => void;
  onEmailAccountsSwitchChangeHandler: (emailAccount: EmailAccount) => void;
  redirectToGeneralSettings: (emailAccount: EmailAccount) => void;
  onSmtpImapModalShow: (emailAccount: EmailAccount) => void;
}): Column[] => {
  const isClientAssociatedVisible =
    hasPermission(Permissions.CLIENT_READ) && isAgency();
  return [
    hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && {
      dataField: 'status',
      text: '',
      headerAlign: 'start',
      cellClasses: 'email-account-switch-cell',
      component: (_cell, row) => (
        <EmailAccountSwitch
          emailAccount={row}
          onChangeHandler={onEmailAccountsSwitchChangeHandler}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'fromName',
      text: 'Sender Name',
      headerAlign: 'start',
      cellClasses: 'sender-name-cell',
      component: (_cell, row) => (
        <SenderNameField
          emailAccount={row}
          redirectToGeneralSettings={redirectToGeneralSettings}
          onSmtpImapModalShow={onSmtpImapModalShow}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      dataField: 'healthScore',
      text: 'Setup Score',
      headerAlign: 'start',
      cellClasses: 'setup-score-cell',
      sort: true,
      onSort: (_field, order) => onSort(EmailAccountsSortBy.SetupScore, order),
      component: (_cell, row) => <SetupScoreField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'activeSequences',
      text: 'Active Sequences',
      headerAlign: 'start',
      cellClasses: 'active-sequences-cell',
      component: (_cell, row, rowIndex) => (
        <ActiveSequencesField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hasPermission(
      Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
    ) && {
      dataField: 'emailSent',
      text: 'Email Sent',
      headerAlign: 'start',
      cellClasses: 'email-sent-cell',
      headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
      component: (_cell, row, rowIndex) => (
        <EmailSentField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    ...(isClientAssociatedVisible
      ? [
          {
            dataField: 'client',
            text: 'Client Associated',
            headerAlign: 'start',
            cellClasses: 'email-account-client-associated',
            sort: true,
            onSort: (_field, order: SortOrder) =>
              onSort(EmailAccountsSortBy.ClientAssociated, order),
            headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
            component: (cell) => {
              if (cell) {
                const { firstName, companyName, email } = cell;
                if (firstName && companyName && email) {
                  return (
                    <div className="sequence-client-associated-name">
                      <OverlayTooltip
                        text={getClientName(cell, 60, {
                          email: true,
                          deleted: true,
                        })}
                        placement={Placement.BottomStart}
                        className="email-account-client-associated-tooltip"
                      >
                        <p>
                          {getClientName(cell, 23, {
                            company: true,
                          })}
                        </p>
                      </OverlayTooltip>
                    </div>
                  );
                }
              }
              return '-';
            },
            headerLoadingSkeleton: <SkeletonLoading width={68} height={14} />,
            cellLoadingSkeleton: <SkeletonLoading width={76} height={14} />,
          },
        ]
      : []),
    {
      dataField: 'tags',
      text: 'Tags',
      headerAlign: 'left',
      cellClasses: 'tag-detail',
      style: () => ({ width: '5rem' }),
      component: (cell) => <ProspectTagsRow tags={cell} />,
      headerLoadingSkeleton: <SkeletonLoading width={29} height={14} />,
      cellLoadingSkeleton: (
        <div className="d-flex">
          <SkeletonLoading width={84} height={24} />
          <div className="ml-2">
            <SkeletonLoading width={64} height={24} />
          </div>
        </div>
      ),
    },
    {
      dataField: 'user',
      text: 'Added By',
      headerAlign: 'start',
      cellClasses: 'added-by-cell',
      component: (_cell, row) => <AddedByField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
  ].filter((col) => col);
};
