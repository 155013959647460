/* eslint-disable @typescript-eslint/naming-convention */
import { RouteComponentProps } from 'react-router';
import { ProspectsFilters } from '../../shared/types/prospects-filters';
import {
  ContactStatusType,
  SortOrder as TableSortOrder,
} from '../sequence/enums';
import { ProspectSortBy as ContactFilterSortBy } from './enums/prospect';
import { SortOrder } from '../../shared/enums/order';
import type {
  FilterLogic,
  FilterOperation,
  FilterSource,
} from './components/prospect-filters-modal/types';

export type IProps = RouteComponentProps;

export type ProspectParams = {
  email?: string;
  search?: string;
  sequenceId?: number;
  pageSize: number;
  pageNum: number;
  tags?: string[];
  status?: ContactStatusType[];
  sortBy?: ProspectSortBy;
  sortOrder?: TableSortOrder;
  isSequenceNone?: boolean;
};

export type ProspectFilters = {
  sortBy: ProspectSortBy;
  sortOrder: TableSortOrder;
};

export type ProspectCountParams = {
  email?: string;
  search?: string;
  sequenceId?: number;
};

export type ProspectPagination = {
  pageNum: number;
  pageSize: number;
};

export type AddToStepParams = {
  stepId?: number;
  sequenceId: string;
  contactIds: number[];
};

export type removeProspectParam = {
  contactId: number;
  sequenceIds: number[];
};

export type markAsRepliedParam = {
  contactId: number;
  stepId: number;
};

export type removeFromSequenceParam = {
  contactIds: number[];
  sequenceId: number;
};

export type SaveContactAttributesPayload = {
  contactId: number;
  attributes: [];
};

export type saveContactDealValuePayload = {
  sequenceId: number;
  attributes: [];
};

export type AddProspectManuallyPayload = {
  attributes: any[];
};

export type AddTagsToProspectsPayload = {
  ids: number[];
  prospects: number[];
  newTags: string[];
};

export type RemoveTagsFromProspectsPayload = {
  tagId: number;
  prospects: number[];
}[];

type BulkActionFilters = ProspectsFilters & {
  sortBy?: ProspectSortBy;
  sortOrder?: TableSortOrder;
  search?: string;
  isSequenceNone?: boolean;
};

export type BulkActionPayload = {
  deSelectedContactIds?: number[];
  contactsFilter?: FilterGroupLogicPayload;
  stepId?: number;
  sequenceId?: string;
  ids?: number[];
  newTags?: string[];
  tagIds?: number[];
};

export enum ProspectSortBy {
  Owner = 'owner',
}

export type SelectAllContacts = {
  isAllProspectsSelected?: boolean;
  contactsFilter?: BulkActionFilters;
  deSelectedContactIds?: any[];
};

export type deleteSecondaryEmailParams = {
  prospectInfoId: number;
  contactId: number;
};

export type changePrimaryEmailInProspectPayload = {
  primaryEmailProspectInfoId: number;
  contactId: number;
};

export type deleteSecondaryPhoneParams = {
  prospectInfoId: number;
  contactId: number;
};

export type FilterGroupLogic = {
  filters: {
    group: number;
    conditions: {
      source: string;
      field: string;
      operation: string;
      value?: string | number | (string | number)[];
    }[];
    logic: FilterLogic;
  }[];
  group_logic: FilterLogic;
};

// Filters

export type FilterGroupLogicPayload = {
  filters: {
    group: number;
    conditions: {
      source: FilterSource;
      field: string;
      operation: FilterOperation | null;
      value?: string | number | (string | number)[];
    }[];
    logic: FilterLogic;
  }[];
  group_logic: FilterLogic;
};

export type ProspectListPayload = {
  filterGroupLogic?: FilterGroupLogicPayload;
  pageSize?: number;
  pageNum?: number;
  sortOrder?: SortOrder;
  sortBy?: ContactFilterSortBy;
  search?: string;
  selectedField?: string[];
  viewId?: number;
};

export type Filters = {
  filterGroupLogic?: FilterGroupLogicPayload;
  pageNum: number;
  pageSize: number;
  selectedFields: any[];
  sortBy: ContactFilterSortBy;
  sortOrder?: SortOrder;
  search: string;

  shouldFetchProspectList: boolean;
  shouldFetchProspectListCount: boolean;
  shouldFetchSavedFilters: boolean;
};

export type ProspectListByIdsPayload = {
  contactIds: string[];
  selectedField: string[];
};

export type SavedFilter = {
  id: number;
  shAccountId: number;
  filterName: string;
  isShared: boolean;
  pinnedAt: string;
  createdAt: string;
  updatedAt: string;
  filterCriteria: FilterGroupLogicPayload;
  created: {
    id: number;
    firstName: string;
    lastName: string;
  };
  teams: any[];
};

export enum PinUnpinActionType {
  Pin = 'pin',
  UnPin = 'unpin',
}

export type ProspectFiltersSequencesClientsPayload = {
  search?: string;
  page: number;
  pageSize: number;
};

export type ProspectFiltersValuesPayload = {
  search?: string;
  page: number;
  pageSize: number;
  key: string;
};

export type ProspectFiltersSaveViewPayload = {
  filterName: string;
  filterCriteria: FilterGroupLogicPayload;
  isShared?: boolean;
  teamIds?: number[];
};

export type ProspectFiltersUpdateViewPayload = Partial<ProspectFiltersSaveViewPayload> & {
  id: string;
};
